import { Component, ViewChild, ElementRef, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { CastingService } from '../../services/casting.service';
import { Casting } from '../../models/Casting';
import { AuthService } from '../../services/auth.service';
import { UploadFileService } from '../../services/upload-file.service';
import { NgForm } from '@angular/Forms';
declare var $:any;

@Component({
  selector: 'app-castings',
  templateUrl: './castings.component.html',
  styleUrls: ['./castings.component.css']
})

export class CastingsComponent implements OnInit {

  //properties
  username: string;
  company_id: number;
  allResults: Casting[];
  searchResults: Casting[];
  castings: Casting[] = [];
  currentJob: Casting;
  showCount: number = 10;
  requestCount: number = 10;
  currentPageIndex: number = 0;
  pageCount: number;
  searchbar: string;
  highlightMatrix: any[] = [];
  matchMatrix: any[];
  loaded: boolean = false;
  displayed_columns: any[] = [];
  hidden_columns: any[] = [];
  hoverIndex: number;
  sortHistory = {};
  now: any;
  showLoading:boolean = false;
  showSuccessMessage:boolean = false;
  formNew:boolean;
  formSwitchable:boolean;
  view: string;
  requests: any[] = [];
  //showShipments: boolean = false; //DEBUGGING
  //@ViewChild('fileSelectionInput') fileSelectionInput?: HTMLInputElement;
  @ViewChild('castingsTable') table?: ElementRef;
  tableWidth: number;
  browserWidth: number; //this is the browser. the width of actual screen may be read with window.screen.width
  lastModal: number;
  /* var @lastResize options:
   * -1 (meaning that the last resize was to shrink the number)
   *
   */
  lastResize: number = 0;

  //properties for attached docs
  documents: any[];
  currentDocument: any;
  selectedFiles: FileList;
  useNewFileName: boolean;
  saveReminder: boolean;
  showDuplicateFileMessage: boolean = false;

  constructor(
    private castingService: CastingService,
    private uploadService: UploadFileService,
    private auth: AuthService,
    private router: Router
  ) {
    this.view = router.url === '/castings/open' ? 'open' : (router.url === '/castings/archive' ? 'archive' : 'all');
  }

  ngOnInit() {
    if(this.auth.session == null) {
      this.auth.getSession().subscribe(session => {
        //console.log(session);
        this.auth.session = session;
        this.username = session.username;
        this.company_id = session.company_id;
        if(this.currentJob !== undefined) {
          this.currentJob.company_id = this.company_id;
        }
      });
    } else {
      this.username = this.auth.session.username;
      this.company_id = this.auth.session.company_id;
    }
    this.now = new Date();
    this.blankCasting();
    this.blankDocumentForm();
    this.allResults = this.castingService.lastCast(this.view);
    if(this.allResults) {
      this.preparePage();
    }

    this.castingService.getCastings(this.view).subscribe(castings => {
      //console.log(castings);
      this.castingService.setCast(castings);
      this.allResults = this.castingService.lastCast(this.view);
      //this.showShipments = true; //DEBUGGING
      //console.log('results updated:',JSON.stringify(this.allResults)); //DEBUGGING
      delete this.searchResults;
      this.preparePage();
      //console.log('displayMatrix:',this.displayMatrix);
    });
  }
  showChange(casting) {
    //console.log('showChange is called.');
    //use this for debugging purposes
    for(let prop in casting) {
      if(prop.includes('date')) {
        //console.log(prop, casting[prop]);
      }
    }
  }
  isDate(date: string): boolean {
    //console.log('isDate is called.');
    var dateObj = new Date(date);
    return dateObj.toLocaleDateString("en-US") !== "Invalid Date";
  }
  formatDate(date) {
    //console.log('formatDate is called.');
    if(date === "now" || this.isDate(date)) {
      let d: any;
      if(date === "now") { 
        this.now = new Date(); 
        d = new Date();
      } else {
        d = new Date(date);
        d.setDate(d.getDate() + 1);
      }
      var month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-');
    } else {
      return "";
    }
  }
  updateFileSelection(files: FileList) {
    //console.log('updateFileSelection is called.');
    this.selectedFiles = files;
  }
  updateCurrentDoc(doc: any) {
    //console.log('updateCurrentDoc is called.');
    this.currentDocument = {};
    for(let prop in doc) {
      this.currentDocument[prop] = doc[prop];
    }
    //console.log('the document was updated for the castings component:', this.currentDocument);
  }
  formSubmit(job: Casting, checkboxInit:boolean=false) {
    console.log('Form submission kicked off for casting job: ',job);
    this.showLoading = true;
    job.lastedituser = this.username;
    let temprow: Casting = {id:job.id};
    for (let property in job) {
      if(
        [
          'archive','pattern','metalcertc','layoutc','xrayc',
          'qualitysubmitted','rawcomplete','machcomplete'
        ].includes(property)
      ) {
        temprow[property] = job[property] ? 'True' : 'False';
      } else if(['metalcertr','layoutr','xrayr'].includes(property)) {
        temprow[property] = job[property] ? 'yes' : 'no';
      } else {
        temprow[property] = job[property];
      }
    }
    if(this.formNew) {
      console.log('Form is new, so we call castingService.addCast(temprow), where temprow=',temprow);
      this.castingService.addCast(temprow);
    } else {
      console.log('Form is NOT new');
      this.updateRequests(job.id,true);
      let i = 0;
      this.allResults.forEach((row) => {
        if(row.id === temprow.id) {
          this.allResults.splice(i,1,temprow);
        }
        i++;
      });
      console.log('We call castingService.editCast(temprow) where temprow=',temprow);
      this.castingService.editCast(temprow);
    }
    delete this.searchResults;
    setTimeout(() => {
      //modal
      document.getElementById('closeCastingModal').click();
    }, 500);
    //console.log('we will now call preparePage()');
    this.preparePage();


    
    job.dateupdated = this.formatDate("now");
    //console.log(job.dateupdated);
    if(job.id == null) {
      job.datecreated = this.formatDate("now");
    }
    //console.log('here is the job we want to add right before it is sent to the api:');
    //console.log(JSON.stringify(job));
    if(job.company_id == null) {//we check to see if company_id is null
      //if so, we must call this.addEditJob from the subscription to getSession AFTER setting company_id
      this.auth.getSession().subscribe(session => {
        this.company_id = session.company_id;
        job.company_id = this.company_id;
        this.addEditJob(job,checkboxInit);
      });
    } else {
      //if not, we can simply call this.addEditJob right away
      this.addEditJob(job,checkboxInit);
    }

  }
  addEditJob(job: Casting, checkboxInit:boolean=false) {
    console.log('addEditJob is called - this is where the api is invoked');
    this.castingService.addEditJob(job as Casting, this.view).subscribe(theJob => {
      console.log('document at the time castingService.addEditJob is subscribed to:', this.currentDocument);
      console.log('First element in response from API (theJob[0]): ',JSON.stringify(theJob[0]));
      if(!checkboxInit) { this.saveMetadataAndUploadFile(theJob[0].jobno); }
      delete this.searchResults;
      if(this.formNew) {
        this.castingService.editCast(theJob[0], true);
        this.allResults = this.castingService.lastCast(this.view);
        this.showLoading = false;
        this.showSuccessMessage = true;
      } else {
        //console.log('requests array before:',JSON.stringify(this.requests));
        let rc = this.updateRequests(theJob[0].id,false);
        //console.log('requests array after:',JSON.stringify(this.requests));
        if(rc == 0) {
          // console.log('case 1');
          // console.log('requests:', rc);
          this.castingService.editCast(theJob[0]);
          this.allResults = this.castingService.lastCast(this.view);
          let total_requests = 0;
          this.requests.forEach(r => {
            total_requests += r[1];
          });
          if(total_requests == 0) {
            this.showLoading = false;
            this.showSuccessMessage = true;
          } else {
            console.log('requests not finished:', this.requests);
            console.log('total requests remaining:', total_requests);
          }
        } else {
          console.log('case 2, showLoading:', this.showLoading);
          console.log('requests:', rc);
        }
      }
      
      
      //close the modal popup and hide the success message
      setTimeout(() => {
        //modal
        document.getElementById('closeCastingModal').click();
        //message
        this.showSuccessMessage = false;
      }, 500);
      this.preparePage();
    });
  }
  saveMetadataAndUploadFile(jobno: string) {
    //console.log('saveMetadataAndUploadFile is called.');
    if(jobno != null && this.selectedFiles != null) {
      //console.log('document as received by saveMetadataAndUploadFile:', this.currentDocument);
      // console.log('selected file(s):', this.selectedFiles);
      
      this.currentDocument.jobnumber = jobno;
      this.currentDocument.folder_prefix = "americanpattern/"+ jobno +"/";
      //store the requested metadata locally
      this.uploadService.serveDocument(this.currentDocument);
      this.setDocumentsFromLocal(jobno);
      //console.log('documents in service before latest id set:',this.uploadService.documents);
      //save the requested metadata to the database
      this.uploadService.addMetadata(this.currentDocument).subscribe(theDocument => {
        if(theDocument != null) {
          //console.log('documents before setting:',this.documents);
          this.uploadService.serveDocument(theDocument[0],true);
          //console.log('documents in service:',this.uploadService.documents);
          this.setDocumentsFromLocal(jobno);
          //console.log('documents after setting:',this.documents);
          if(this.upload(jobno)) {
            //console.log('File upload successful.');
          } else {
            //if the upload to s3 is not successful, we delete the metadata from our database
            this.uploadService.deleteMetadata(this.currentDocument.id as number).subscribe(docs => {
              this.uploadService.documents = docs;
              this.setDocumentsFromLocal(jobno);
            });
          }
        }
      });
    }
  }
  upload(folder) {
    //console.log('upload is called.');
    const file = this.selectedFiles.item(0);
    return this.uploadService.uploadfile(file,folder);
  }
  deleteJob(job: Casting) {
    //console.log('deleteJob is called');
    //close the modal popup
    document.getElementById('closeDeleteJobModal').click();
    //remove the job from the list
    let i = 0;
    this.allResults.forEach((jobrow) => {
      if(jobrow.id === job.id) {
        this.allResults.splice(i,1);
      } else {
        i++;
      }
    });
    //reset searchResults and prepare data for page viewing
    delete this.searchResults;
    this.preparePage();

    //request a contact deletion from the service
    this.castingService.deleteJob(job.id as number, this.view).subscribe(jobs => {
      this.castingService.setCast(jobs);
      this.allResults = this.castingService.lastCast(this.view);
    });
    //reset currentContact
    this.blankCasting();
  }
  updateRequests(id: number, addRequest: boolean) {
    //console.log('updateRequests is called');
    let i: number;
    let q: number;

    let index = 0;
    this.requests.forEach(job => {
      if(job[0] == id) { i = index; q = job[1]; return; }
      index++;
    });
    if(i === undefined) {
      //console.log('updateRequests called - id of '+id+ ' not found');
      if(addRequest) { 
        //console.log('adding a new request, returning 1');
        this.requests.push([id,1]);
        return 1;
      } else {
        //console.log('not adding new request, returning 0');
        return 0;
      }
    } else {
      //console.log('updateRequests called - id successfully located');
      let newQ = addRequest ? q+1 : (q <= 0 ? 0 : q-1);
      if(addRequest) {
        //console.log('adding a request, returning',newQ);
      } else {
        //console.log('removing a request, returning',newQ);
      }
      this.requests.splice(i,1,[id,newQ]);
      return newQ;
    }

  }
  toggleComplete(casting) {
    //console.log('toggleComplete is called');
    this.setCurrentJob(casting,true);
    this.currentJob.archive = !this.currentJob.archive;
    this.formSubmit(this.currentJob,true);
  }
  toggleCheckbox(casting,col) {
    //console.log('toggleCheckbox is called');
    if(casting.id != null) {
      this.setCurrentJob(casting,true);
      if(col == 'archive') {
        this.currentJob.archive = !this.currentJob.archive;
      } else if(col == 'pattern') {
        this.currentJob.pattern = !this.currentJob.pattern;
      } else if(col == 'metalcertr') {
        this.currentJob.metalcertc = !this.currentJob.metalcertc;
      } else if(col == 'layoutr') {
        this.currentJob.layoutc = !this.currentJob.layoutc;
      } else if(col == 'xrayr') {
        this.currentJob.xrayc = !this.currentJob.xrayc;
      } else if(col == 'qualitysubmitted') {
        this.currentJob.qualitysubmitted = !this.currentJob.qualitysubmitted;
      } else if(col == 'rawcount') {
        this.currentJob.rawcomplete = !this.currentJob.rawcomplete;
      } else if(col == 'machcount') {
        this.currentJob.machcomplete = !this.currentJob.machcomplete;
      } else {
        return null;
      }
      this.formSubmit(this.currentJob,true);
    }
  }

  toggleDupFileMessage(flag: boolean) { this.showDuplicateFileMessage = flag; }
  toggleSaveReminder(flag: boolean) { this.saveReminder = flag; }
  toggleNewFileName(flag: boolean) { this.useNewFileName = flag; }

  setCurrentJob(casting,checkboxInit:boolean=false) {
    //console.log('setCurrentJob is called');
    let value: any;
    let dateString: string;
    let dateObj: any;

    this.formNew = false;
    this.formSwitchable = true;
    var toggle = document.getElementById('formSwitchToggle') as HTMLInputElement;
    var mirror = document.getElementById('toggleMirror') as HTMLInputElement;
    // toggle.click();
    // toggle.checked=true;
    
    toggle.checked=true;
    toggle.dispatchEvent(new Event('change'));
  
    
    //console.log(toggle.checked);

    mirror.value = 'true';

    for (let prop in casting) {
      if([
        'layoutr','layoutc','xrayr','xrayc','metalcertr','metalcertc','qualitysubmitted',
        'rawcomplete','pattern','machcomplete','archive'
      ].includes(prop)) {
        value = ["True","true","Yes","yes"].includes(casting[prop]) ? true : false;
      } else if(['machship', 'finalship'].includes(prop)) {
        if(casting[prop] != null && casting[prop].length > 0) {
          value = typeof casting[prop] == "string" ? JSON.parse(casting[prop]) : casting[prop];
          //if(this.showShipments){console.log(value);} //DEBUGGING
          for(let i=0; i < value.length; i++) {
            if(value[i].date !== "") { value[i].date = this.formatDate(value[i].date); }
          }
        } else {
          value = [{"count": 0, "date": ""}];
        }
      } else if(['machduedate', 'rawduedate'].includes(prop)) {
        value = (casting[prop] === "" || casting[prop] == null) ? "" : this.formatDate(casting[prop]);
      } else {
        value = casting[prop];
      }
      this.currentJob[prop] = value;
    }

    //here we bring in the relevant documents for this job
    if(!checkboxInit){ this.setDocuments(casting.jobno); }
    
    if(document.querySelector(".toggle-group") == null) {
      $('#formSwitchToggle').bootstrapToggle();
    }

    this.clearFileSelectionInput();
  }
  setDocuments(jobnumber: string) {
    //console.log('setDocuments was called, resetting document array from database');
    this.setDocumentsFromLocal(jobnumber);
    this.setDocumentsFromAPI(jobnumber);
  }
  clearFileSelectionInput() {
    //console.log('clearFileSelectionInput is called');
    //clear the file selection input
    var fsi = document.getElementById('fileSelector') as HTMLInputElement;
    if(fsi != null) { fsi.value = ""; }

    //remove warning message for duplicate filename use
    this.showDuplicateFileMessage = false;

    //remove save-reminder message
    this.saveReminder = false;

    //hide the new file name input
    this.useNewFileName = false;
  }
  setDocumentsFromAPI(jobnumber: string) {
    //console.log('setDocumentsFromAPI is called');
    this.uploadService.getMetadata().subscribe(docs => setTimeout(() => {
      if(docs != null) {
        this.uploadService.documents = docs;
        this.setDocumentsFromLocal(jobnumber);
      }
    }, 0));
  }
  
  setDocumentsFromLocal(jobnumber: string) {
    //console.log('setDocumentsFromLocal is called');
    if(this.uploadService.documents != null) {
      //console.log('documents in service when setDocumentsFromLocal called:',this.uploadService.documents);
      let temp_documents = [];
      this.uploadService.documents.forEach(doc => {
        if(doc.jobnumber == jobnumber) {
          temp_documents.push(doc);
        }
      });
      this.documents = temp_documents;
    }
  }
  blankCasting() {
    //console.log('blankCasting is called');
    this.formNew = true;
    if(this.documents != null) { this.documents = []; }
    this.formSwitchable = false;
    this.currentJob = {
      id: null,
      company_id: this.company_id,
      contact: "",
      customer: "",
      phone: "",
      partno: "",
      jobno: "",
      purchaseorder: "",
      description: "",
      irontype: "",
      paint: "",
      weightlbs: 0,
      layoutr: false,
      layoutc: false,
      xrayr: false,
      xrayc: false,
      metalcertr: false,
      metalcertc: false,
      qualitysubmitted: false,
      rawcount: 0,
      rawduedate: "",
      rawcomplete: false,
      pattern: false,
      machcount: 0,
      machvendor: "",
      machduedate: "",
      machcomplete: false,
      machship: [{count: 0, date: ""}],
      finalship: [{count: 0, date: ""}],
      comments: "",
      archive: false
    };
    if(this.currentDocument) {
      this.currentDocument.folder_prefix = "americanpattern/";
    }
    this.blankDocumentForm();
  }
  blankDocument(clear: boolean) {
    //console.log('blankDocument is called');
    if(clear) {
      this.blankDocumentForm();
    }
  }
  blankDocumentForm() {
    //console.log('blankDocumentForm is called');
    this.currentDocument = {
      id: null,
      company_id: 2,
      folder_prefix: "americanpattern/",
      filename: "",
      jobnumber: "",
      comments: ""
    };
    this.clearFileSelectionInput();
  }
  changeFormNew(formNew: boolean) {
    this.formNew = formNew;
  }
  preparePage() {
    //console.log('preparePage is called');
    //console.log('as the first step of preparePage(), we call setView();')
    this.setView();
    //console.log('second step of preparePage(): determine which columns to display...');
    if(this.castings && this.displayed_columns.length == 0) {
      //console.log('castings is set and displayed columns is empty, so we proceed. Note castings and displayed_columns:');
      //console.log(this.castings);
      //console.log(this.displayed_columns);
      for (let prop in this.castings[0]) {
        if( //columns to include which do not require a name transform get added here:
          //the following is a list of columns which should NOT be included for display...
          ![
            'id','company_id','rawcomplete','machcomplete','layoutc','xrayc','metalcertc',
            'customer','phone','machship','finalship','comments'
          ].includes(prop)
        ) {
          this.displayed_columns.push(prop);
        }
      }
      //console.log('we have completed the second step of preparePage(), note the state of displayed_columns:');
      //console.log(this.displayed_columns);
    } else {
      //console.log('second step of preparePage() cannot be completed because of the state of castings and/or displayed_columns:');
      //console.log(this.castings);
      //console.log(this.displayed_columns);
    }
    if(!this.loaded) { this.onResize(null); }
    this.loaded = true;
    //console.log('preparePage() has finished running');
  }
  displayTitle(prop) {
    //console.log('displayTitle is called');
    if(prop === 'partno') {
      return 'Part #';
    } else if(prop === 'archive') {
      return 'Complete';
    } else if(prop === 'jobno') {
      return 'Job #';
    } else if(prop === 'purchaseorder') {
      return 'Purchase Order';
    } else if(prop === 'rawcount') {
      return 'Raw';
    } else if(prop === 'machcount') {
      return 'Machine';
    } else if(prop === 'layoutr') {
      return 'Layout';
    } else if(prop === 'xrayr') {
      return 'XRay';
    } else if(prop === 'metalcertr') {
      return 'Metal Cert';
    } else if(prop === 'qualitysubmitted') {
      return 'Quality Sub';
    } else if(prop === 'machvendor') {
      return 'Mach Vendor';
    } else if(prop === 'rawduedate') {
      return 'Raw Due';
    } else if(prop === 'machduedate') {
      return 'Mach Due';
    } else if(prop === 'description') {
      return 'Desc';
    } else if(prop === 'irontype') {
      return 'Iron Type';
    } else if(prop === 'weightlbs') {
      return 'Wt Lbs';
    } else {
      return prop;
    }
  }
  displayValue(row, column) {
    //console.log('displayValue is called');
    return (
      (column == 'archive' || column == 'pattern' || column == 'qualitysubmitted') && 
      row[column] != 'True' && row[column] != 'False'
    ) || (
      column == 'rawcount' && row['rawcomplete'] != 'True' && row['rawcomplete'] != 'False'
    ) || (
      column == 'machcount' && ((row['machcomplete'] != 'True' && row['machcomplete'] != 'False') || (row['machcount'] == '' && row['Mach Due'] == ''))
    ) || (
      column == 'layoutr' && ((row['layoutc'] != 'True' && row['layoutc'] != 'False') || row['layoutr'] != 'yes')
    ) || (
      column == 'xrayr' && ((row['xrayc'] != 'True' && row['xrayc'] != 'False') || row['xrayr'] != 'yes')
    ) || (
      column == 'metalcertr' && ((row['metalcertc'] != 'True' && row['metalcertc'] != 'False') || row['metalcertr'] != 'yes')
    ) || (
      !['partno','archive','rawcount','pattern','machcount','layoutr','xrayr','metalcertr','qualitysubmitted'].includes(column)
    );
  }
  displayCheckbox(row, column) {
    //console.log('displayCheckbox is called');
    return !this.displayValue(row, column) && column != 'partno';
  }
  valueDisplayed(row, column) {
    //console.log('valueDisplayed is called');
    if(['rawduedate', 'machduedate'].includes(column)) {
      if(row[column] != null) {
        let dateString = row[column].split(' ').join('T');
        dateString = dateString.includes('Z') ? dateString : dateString + 'Z';
        let dateObj = new Date(dateString);
        dateObj.setDate(dateObj.getDate() + 1);
        if(dateObj.toLocaleDateString("en-US") == "Invalid Date") {
          return "";
        } else {
          return dateObj.toLocaleDateString("en-US");
        }
      } else {
        return "";
      }
    } else {
      return row[column];
    }
  }
  boxChecked(row, column) {
    //console.log('boxChecked is called');
    if(column == 'rawcount') {
      return row['rawcomplete'] == 'True';
    } else if(column == 'machcount') {
      return row['machcomplete'] == 'True';
    } else if(column == 'layoutr') {
      return row['layoutc'] == 'True';
    } else if(column == 'xrayr') {
      return row['xrayc'] == 'True';
    } else if(column == 'metalcertr') {
      return row['metalcertc'] == 'True';
    } else {
      return row[column] == 'True';
    }
  }
  checkboxLabel(row, column) {
    //console.log('checkboxLabel is called');
    if(['rawcount','machcount'].includes(column)) {
      return row[column];
    } else {
      return '';
    }
  }

  
  deadline(row, days: number) {
    //console.log('deadline is called');
    var trigger = new Date();
    trigger.setDate(trigger.getDate() + days);
    let dateString = row.rawduedate != null ? row.rawduedate.split(' ').join('T') : "";
    dateString = dateString.includes('Z') ? dateString : dateString + 'Z';
    let dateObj = new Date(dateString);
    dateObj.setDate(dateObj.getDate() + 1);
    return dateObj.toLocaleDateString("en-US") != "Invalid Date" && 
      trigger > dateObj && 
      row.rawcomplete != 'True' && 
      row.machcomplete != 'True' &&
      row.archive != 'True';
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    //calibrate local tableWidth and browserWidth variables
    //console.log('onResize was called');
    this.browserWidth = window.innerWidth;
    this.tableWidth = this.table == null ? this.browserWidth - 30 : this.table.nativeElement.offsetWidth;
    
    const diff = this.browserWidth - this.tableWidth;
    
    if(diff >= 30 && this.lastResize !== -1) {
      //add a column if possible
      if(this.hidden_columns.length > 0) { this.displayed_columns.push(this.hidden_columns.pop()); }
      //record lastResize
      this.lastResize = 1;
      //call self
      setTimeout(() => {
        this.onResize(event);
      },10);
    } else if(diff < 30) {
      //remove a column if possible/desired
      if(this.displayed_columns.length > 2) { this.hidden_columns.push(this.displayed_columns.pop()); }
      //record lastResize
      this.lastResize = -1;
      //call self
      setTimeout(() => {
        this.onResize(event);
      },10);
    } else {
      //reset lastResize
      this.lastResize = 0;
      //remove a column to make room for the "More ..." column
      if(this.hidden_columns && this.displayed_columns) {
        this.hidden_columns.push(this.displayed_columns.pop());
      }
    }

    if(this.lastModal != null) {
      //console.log('something happening here');
      if(document.getElementById('closeMore' + this.lastModal) != null) {
        document.getElementById('closeMore' + this.lastModal).click();
      }
      if(document.getElementById('closeArchive' + this.lastModal) != null) {
        document.getElementById('closeArchive' + this.lastModal).click();
      }
      this.lastModal = null;
    }
  }

  lastModalOpen(last) {
    //console.log('lastModalOpen is called');
    this.lastModal = last;
  }
  displayMatrix: any[];
  setDisplayMatrix() {
    this.displayMatrix = [];
    this.castings.forEach(row => {
      let displayrow = {};
      for(let column in row) {
        let col_display_vector = {
          title: '',
          checkbox: false,
          label: null,
          value: null
        };
        col_display_vector.title = this.displayTitle(column);
        col_display_vector.checkbox = this.displayCheckbox(row, column);
        if(col_display_vector.checkbox) {
          col_display_vector.label = this.checkboxLabel(row,column);
          col_display_vector.value = this.boxChecked(row,column);
        } else {
          col_display_vector.value = this.valueDisplayed(row,column);
        }
        Object.defineProperty(displayrow, column, { value: col_display_vector });
      }
      //now we add some information about deadlines:
      let deadline_vector = [];
      deadline_vector.push(this.deadline(row,7));
      deadline_vector.push(this.deadline(row,14));
      Object.defineProperty(displayrow, 'deadline', { value: deadline_vector });
      this.displayMatrix.push(displayrow);
    });
  }
  setView() {
    //console.log('setView is called');
    //console.log('current page index:',this.currentPageIndex);
    //console.log('for the first step of setView(), we evaluate this.searchResults to a boolean:');
    if(this.searchResults) {
      //console.log('searchResults evaluates to true, so we can load up castings from the searchResults array');
      //console.log('note the state of castings before this step:');
      //console.log(this.castings);
      //console.log('note the state of searchResults before this step:');
      //console.log(this.searchResults);

      //if(this.showShipments){ console.log('results 3:',JSON.stringify(this.searchResults)); } //DEBUGGING
      this.castings = [];
      this.matchMatrix = [];
      this.showCount = Math.min(this.requestCount, this.searchResults.length);
      this.pageCount = this.showCount == 0 ? 0 : Math.ceil(this.searchResults.length/this.showCount);
      for (
        let i = this.currentPageIndex * this.showCount; 
        i < this.currentPageIndex * this.showCount + Math.min(this.searchResults.length - this.currentPageIndex * this.showCount, this.requestCount); 
        i++
      ) {
        this.castings.push(this.searchResults[i]);
        if(this.highlightMatrix.length > 0) {
          this.matchMatrix.push(this.highlightMatrix[i]);
        }
      }
      this.setDisplayMatrix();
      //console.log('we finish loading castings... now:');
      //console.log('note the state of castings:');
      //console.log(this.castings);
      //console.log('note the state of searchResults:');
      //console.log(this.searchResults);
    } else {
      //console.log('searchResults evaluates to false, so we must call search()');
      this.search();
    }
    //console.log('setView() has finished running');
  }

  prevPage() {
    //console.log('prevPage is called');
    if(this.currentPageIndex > 0) {
      this.currentPageIndex -= 1;
      this.setView();
    }
  }
  nextPage() {
    //console.log('nextPage is called');
    if(this.currentPageIndex + 1 < this.pageCount) {
      this.currentPageIndex += 1;
      this.setView();
    }
  }
  search(resetIndex: boolean = false) {
    //console.log('search is called');
    //console.log('search() can only execute if allResults evaluates to true:');
    if(this.allResults) {
      //console.log('which it does, so we may proceed');
      //if(this.showShipments){ console.log('results 2:',JSON.stringify(this.allResults)); } //DEBUGGING
      if(this.searchbar) {
        //console.log('there is content in the searchbar:');
        //console.log(this.searchbar);
        //console.log('so we proceed by filtering according to search content..');
        this.searchResults = [];
        this.highlightMatrix = [];
        for (let i = 0; i < this.allResults.length; i++) {
          let include: boolean = false;
          let highlightRow: any = {};
          for (let prop in this.allResults[i]) {
            if(this.allResults[i][prop] != null) {
              if(
                this.allResults[i][prop].toString().toLowerCase().includes(this.searchbar.toLowerCase())
              ) {
                //row-column contains search element
                include = true;
                highlightRow[prop] = true;
              } else {
                highlightRow[prop] = false;
              }
            } else {
              highlightRow[prop] = false;
            }
          }
          if(include) {
            this.searchResults.push(this.allResults[i]);
            this.highlightMatrix.push(highlightRow);
          }
        }
        //console.log('once this step is complete, note the content of searchResults:');
        //console.log(this.searchResults);
      } else {
        //console.log('there is no content in the searchbar so we set searchResults to allResults');
        this.searchResults = this.allResults;
        this.highlightMatrix = [];
      }
      if(resetIndex) { this.currentPageIndex = 0; }
      //console.log('now we are ready to call setView() from within search()');
      this.setView();
    } else {
      //console.log('which it does not, so we do nothing else');
    }
    //console.log('search() has finished running');
  }

  matchHidden(index) {
    //console.log('matchHidden is called');
    if(this.matchMatrix.length > index) {
      for (let i = 0; i < this.hidden_columns.length; i++) {
        if(this.matchMatrix[index][this.hidden_columns[i]]) {
          return true;
        }
      }
    }
    return false;
  }

  sortResults(property) {
    //console.log('sortResults is called');
    let asc = true;
    let lt_test: boolean;
    let gt_test: boolean;

    if(!this.searchResults) {
      this.search();
    }
    if(this.sortHistory[property] != null) {
      asc = this.sortHistory[property];
    } else {
      Object.defineProperty(this.sortHistory, property, {
        value: asc,
        writable: true
      });
    }
    if(this.searchResults != null) {
      if(['rawcount','machcount','layoutr','xrayr','metalcertr'].includes(property)) {
        let p2: string;
        if(property == 'rawcount') {
          p2 = 'rawcomplete';
        } else if(property == 'machcount') {
          p2 = 'machcomplete';
        } else if(property == 'layoutr') {
          p2 = 'layoutc';
        } else if(property == 'xrayr') {
          p2 = 'xrayc';
        } else if(property == 'metalcertr') {
          p2 = 'metalcertc';
        }
        this.searchResults = this.searchResults.sort((a,b) => {
          lt_test = property.includes('count') ? (parseInt(a[property],10) || 0) < (parseInt(b[property],10) || 0) : a[property] < b[property];
          gt_test = property.includes('count') ? (parseInt(a[property],10) || 0) > (parseInt(b[property],10) || 0) : a[property] > b[property];
          if(lt_test) { 
            return asc ? -1 : 1;
          } else if (gt_test) {
            return asc ? 1 : -1;
          } else {
            if(a[p2] == null || b[p2] == null) {
              //case where some entry is null/undef
              lt_test = a[p2] == null && b[p2] != null;
              gt_test = a[p2] != null && b[p2] == null;
            } else {
              //make alphanumeric comparisons case-insensitive
              lt_test = a[p2].toString().trim().toLowerCase() < b[p2].toString().trim().toLowerCase();
              gt_test = a[p2].toString().trim().toLowerCase() > b[p2].toString().trim().toLowerCase();
            }
            return lt_test ? (asc ? -1 : 1) : (gt_test ? (asc ? 1 : -1) : 0);
          }
        });
      } else {
        this.searchResults = this.searchResults.sort((a,b) => {
          if(['jobno','weightlbs'].includes(property)) {
            //integer comparison where possible
            lt_test = (parseInt(a[property],10) || a[property]) < (parseInt(b[property],10) || b[property]);
            gt_test = (parseInt(a[property],10) || a[property]) > (parseInt(b[property],10) || b[property]);
          } else if(['rawduedate','machduedate'].includes(property)) {
            //date comparison
            lt_test = a[property] < b[property];
            gt_test = a[property] > b[property];
          } else if(a[property] == null || b[property] == null) {
            //case where some entry is null
            lt_test = a[property] == null && b[property] != null;
            gt_test = a[property] != null && b[property] == null;
          } else {
            //make alphanumeric comparisons case-insensitive
            lt_test = a[property].toString().trim().toLowerCase() < b[property].toString().trim().toLowerCase();
            gt_test = a[property].toString().trim().toLowerCase() > b[property].toString().trim().toLowerCase();
          }
          return lt_test ? (asc ? -1 : 1) : (gt_test ? (asc ? 1 : -1) : 0);
        });
      }
    }


    this.sortHistory[property] = !asc;
    this.currentPageIndex = 0;
    this.setView();
  }
}
