import { Component, OnInit } from '@angular/core';
import { DetailService } from '../../services/detail.service';
import { Detail } from '../../models/Detail';

@Component({
  selector: 'app-jobdescription',
  templateUrl: './jobdescription.component.html',
  styleUrls: ['./jobdescription.component.css']
})
export class JobDescComponent implements OnInit {

  // Properties
  jobdescriptions: Detail[];
  displayed: Detail[];
  searchResults: Detail[];
  showCount: number = 24;
  requestCount: number = 24;
  currentPageIndex: number = 0;
  pageCount: number;
  data: any;
  loaded: boolean = false;
  private checkInQueue: boolean = false;
  formNew: boolean = true;
  currentJobDesc: Detail;
  searchbar: string;

  constructor(private jobdescriptionService: DetailService) {}


  ngOnInit() { // use this to fill properties from ajax call to server etc.
    
    this.blankJobDescForm();

    this.jobdescriptions = JSON.parse(localStorage.getItem('jobdescriptions'));
    if(this.jobdescriptions) {
      this.setView();
      this.loaded = true;
    }

    this.jobdescriptionService.getDetails("jobdescription").subscribe(jobdescriptions => {
      this.jobdescriptions = jobdescriptions;
      localStorage.setItem('jobdescriptions', JSON.stringify(this.jobdescriptions));
      this.setView();
      this.loaded = true;
    });

  }
  prevPage() {
    if(this.currentPageIndex > 0) {
      this.currentPageIndex -= 1;
      this.setView();
    }
  }
  nextPage() {
    if(this.currentPageIndex + 1 < this.pageCount) {
      this.currentPageIndex += 1;
      this.setView();
    }
  }
  setView() {
    //console.log('execute setView()')
    if(this.searchResults) {
      //console.log('searchResults not empty so we complete all the logic for setView');
      //console.log('by the way, check out searchResults:');
      //console.log(this.searchResults);
      this.displayed = [];
      this.showCount = Math.min(this.requestCount, this.searchResults.length);
      this.pageCount = this.showCount == 0 ? 0 : Math.ceil(this.searchResults.length/this.showCount);
      this.currentPageIndex = Math.min(this.currentPageIndex, Math.max(this.pageCount - 1, 0));
      for (
        let i = this.currentPageIndex * this.showCount; 
        i < this.currentPageIndex * this.showCount + Math.min(this.searchResults.length - this.currentPageIndex * this.showCount, this.requestCount); 
        i++
      ) {
        this.displayed.push(this.searchResults[i]);
      }
      //console.log(this.displayed);
    } else {
      //console.log('searchResults empty so we must execute search()');
      this.search();
    }
  }

  search() {
    //console.log('execute search()');
    if(this.jobdescriptions) {
      //console.log('jobdescriptions not empty so we proceed...');
      if(this.searchbar) {
        //console.log('searchbar not empty so we go through jobdescriptions and populate searchResults');
        
        this.searchResults = [];
        for (let i = 0; i < this.jobdescriptions.length; i++) {
          if(this.jobdescriptions[i].name.toString().toLowerCase().includes(this.searchbar.toLowerCase())) {
            this.searchResults.push(this.jobdescriptions[i]);
          }
        }
      } else {
        //console.log('searchbar is empty so we set searchResults to jobdescriptions');
        this.searchResults = this.jobdescriptions;
      }
      //this.currentPageIndex = 0;
      //console.log('search() is now ready to call setView()');
      this.setView();
    }
  }

  onSubmit({value, valid}: {value: Detail, valid: boolean}) {
    //close the modal popup
    document.getElementById('closeaddEditJobDescModal').click();
    //send create/update request
    if(valid) {
      if(this.formNew) {
        this.jobdescriptions.unshift(this.currentJobDesc);
      } else {
        let i = 0;
        this.jobdescriptions.forEach((jobdescription) => {
          if(jobdescription.id === this.currentJobDesc.id) {
            this.jobdescriptions.splice(i,1,{
                id: this.currentJobDesc.id,
                company_id: this.currentJobDesc.company_id,
                name: this.currentJobDesc.name
            });
          }
          i++;
        });
      }
      //reset searchResults and prepare data for page viewing
      delete this.searchResults;
      this.setView();

      //request the create/update from the service
      this.jobdescriptionService.addEditDetail("jobdescription", {
        id:this.currentJobDesc.id,
        company_id:this.currentJobDesc.company_id,
        name:this.currentJobDesc.name
      } as Detail).subscribe(jobdescriptions => {
        this.jobdescriptions = jobdescriptions;
        delete this.searchResults;
        localStorage.setItem('jobdescriptions', JSON.stringify(this.jobdescriptions));
        this.setView();
      });
      //reset form
      if(this.formNew) { this.blankJobDescForm(); }
    }
  }

  deleteJobDesc() {
    //close the modal popup
    document.getElementById('closeDeleteJobDescModal').click();
    //remove the jobdescription from the list
    let i = 0;
    this.jobdescriptions.forEach((jobdescription) => {
      if(jobdescription.id === this.currentJobDesc.id) {
        this.jobdescriptions.splice(i,1);
      } else {
        i++;
      }
    });
    //reset searchResults and prepare data for page viewing
    delete this.searchResults;
    this.setView();

    //request a jobdescription deletion from the service
    this.jobdescriptionService.deleteDetail("jobdescription", this.currentJobDesc.id as number).subscribe(jobdescriptions => {
      this.jobdescriptions = jobdescriptions;
      localStorage.setItem('jobdescriptions', JSON.stringify(this.jobdescriptions));

      //reset searchResults and prepare data for page viewing
      delete this.searchResults;
      this.setView();
    });
    //reset currentJobDesc
    this.blankJobDescForm();
  }

  refreshForm() {
    this.formNew=true;
    this.blankJobDescForm();
  }

  blankJobDescForm() {
    this.currentJobDesc = {
      id: null,
      company_id: 2,
      name: ""
    };
  }

}
