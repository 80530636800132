import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import { Subject } from 'rxjs/Subject';
import { Casting } from '../models/Casting';
import { environment } from '../../environments/environment';
const httpOptions = {
  withCredentials: true,
  headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'X-API-KEY': 'insert_api_key_here'
  })
}

@Injectable({
  providedIn: 'root'
})
export class CastingService {
  castingsUrl: string = environment.apiUrl+'?castings';
  addEditUrl: string = environment.apiUrl+'?addEditJob&castings';
  deleteUrl: string = environment.apiUrl+'?deleteJob&castings';
  addShipUrl: string = environment.apiUrl+'?addShipment=8392&castings';
  url: string;
  castings: any[];

  constructor(private http: HttpClient) {}
  lastCast(view: string) {
    if(this.castings != null) {
      let returnArray = [];
      if(view == 'archive') {
        this.castings.forEach(job => {
          if(job.archive == 'True') {
            returnArray.push(job);
          }
        });
      } else if(view == 'open') {
        this.castings.forEach(job => {
          if(job.archive == 'False') {
            returnArray.push(job);
          }
        });
      } else {
        return this.castings;
      }
      return returnArray;
    } else {
      return null;
    }
  }
  setCast(castings: Casting[]) {
    this.castings = castings;
  }
  addCast(casting: Casting) {
    if(this.castings != null) {
      this.castings.unshift(casting);
    }
  }
  editCast(casting: Casting, swapOutNull: boolean = false) {
    if(this.castings != null) {
      let i = 0;
      this.castings.forEach((row) => {
        if(row.id === casting.id || (swapOutNull && row.id === null)) {
          this.castings.splice(i,1,casting);
        }
        i++;
      });
    }
  }
  prepareAndEditCast(job: Casting, swapOutNull: boolean = false) {
    let temprow: Casting = {id:job.id};
    for (let property in job) {
      if(
        [
          'archive','pattern','metalcertc','layoutc','xrayc',
          'qualitysubmitted','rawcomplete','machcomplete'
        ].includes(property)
      ) {
        temprow[property] = job[property] ? 'True' : 'False';
      } else if(['metalcertr','layoutr','xrayr'].includes(property)) {
        temprow[property] = job[property] ? 'yes' : 'no';
      } else {
        temprow[property] = job[property];
      }
    }
    this.editCast(temprow, swapOutNull);
  }
  getCastings(view: string) : Observable<Casting[]> {
    this.url = this.castingsUrl + (view == 'open' ? '=open' : (view == 'archive' ? '=archive' : '=all'));
    return this.http.get<Casting[]>(this.url, httpOptions);
  }
  addEditJob(job: Casting, view: string): Observable<Casting> {
    this.url = this.addEditUrl + (view == 'open' ? '=open' : (view == 'archive' ? '=archive' : '=all'));
    console.log('We make request to API at this url: ',this.url);
    return this.http.post<Casting>(this.url, job, httpOptions);
  }
  deleteJob(id: number, view: string): Observable<Casting[]> {
    this.url = this.deleteUrl + (view == 'open' ? '=open' : (view == 'archive' ? '=archive' : '=all'));
    return this.http.post<Casting[]>(this.url, id, httpOptions);
  }
  jobnoExists(jobno: string, company_id: number): Observable<boolean> {
    const url = this.castingsUrl + "=locate&job="+jobno+"&co="+company_id;
    return this.http.get<boolean>(url, httpOptions);
  }
  addShipment(shipment): Observable<any> {
    return this.http.post<any>(this.addShipUrl, shipment, httpOptions);
  }
}
