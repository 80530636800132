import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    public auth: AuthService, 
    public router: Router
  ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    //console.log('canActivate() executed');
    if (!this.auth.isAuthenticated(route, state)) {
      this.router.navigate(['/login']);
      return false;
    } else {
      return true;
    }
  }
}