import { Component, ViewChild, ElementRef, OnInit, HostListener } from '@angular/core';
import { DetailService } from '../../services/detail.service';
import { Detail } from '../../models/Detail';
import { UploadFileService } from '../../services/upload-file.service';
import { CastingService } from '../../services/casting.service';
import { AnyLengthString } from 'aws-sdk/clients/comprehend';
import { NgForm } from '@angular/Forms';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {

  // Properties
  documents: any[];
  displayed: any[];
  searchResults: any[];
  showCount: number = 10;
  requestCount: number = 10;
  currentPageIndex: number = 0;
  pageCount: number;
  data: any;
  loaded: boolean = false;
  displayed_columns: any[] = [];
  hidden_columns: any[] = [];
  private checkInQueue: boolean = false;
  private checkInProgress: boolean = false;
  formNew: boolean = true;
  searchbar: string;
  highlightMatrix: any[] = [];
  matchMatrix: any[];
  jobs: any[];
  filteredJobs: any[];
  filterInProgress: boolean = false;
  filterInQueue: boolean = false;
  matchNotFoundMsg: boolean = false;
  signedUrl: string;
  sortHistory = {};

  @ViewChild('documentsTable') table?: ElementRef;
  @ViewChild('fileSelectionInput') fileSelectionInput?: ElementRef;
  @ViewChild('documentForm') documentForm?: NgForm;

  tableWidth: number;
  browserWidth: number; //this is the browser. the width of actual screen may be read with window.screen.width
  lastModal: number;
  /* var @lastResize options:
   * -1 (meaning that the last resize was to shrink the number)
   *
   */
  lastResize: number = 0;

  showLoading: boolean;
  showSuccessMessage: boolean;
  selectedFiles: FileList;
  currentDocument: any;
  filenameUnique: boolean;
  showDuplicateFileMessage: boolean = false;
  useNewFileName: boolean;
  formReady: boolean=true;

  constructor(
    private uploadService: UploadFileService,
    private jobService: CastingService
  ) { }
 
  ngOnInit() {
    this.blankDocumentForm();
    this.documents = this.uploadService.documents;
    if(this.documents) {
      this.preparePage();
    }
    this.uploadService.getMetadata().subscribe(docs => {
      this.uploadService.documents = docs;
      //this.uploadService.documents = null;
      this.documents = this.uploadService.documents;
      this.preparePage();
    });
    this.jobs = this.jobService.lastCast('open');
    if(this.jobs != null) {
      this.filteredJobs = this.jobs;
    } else {
      this.jobService.getCastings('all').subscribe(jobs => {
        this.jobService.setCast(jobs);
        this.jobs = this.jobService.lastCast('open');
        this.filteredJobs = this.jobs;
      });
    }
  }
 
  upload(folder) {
    const file = this.selectedFiles.item(0);
    var overwrite = this.useNewFileName ? this.currentDocument.filename : null;
    //console.log('overwrite passed:',overwrite);
    return this.uploadService.uploadfile(file,folder,overwrite);
  }
  updateDocumentFromJob(job:any=null) {
    //note: the instant before this is called, conditions are met which momentarily displays the
    //invalid feedback for the job no. field. This is because until we set jobnumber (below) the form
    //"thinks" we have touched the input, but left it blank; and for a small fraction of a second, we have.
    //this is a very minor issue, purely cosmetic, but I am making a note here so that it can be cleaned up later.
    
    if(job != null) {this.currentDocument.jobnumber=job.jobno;}
    if(this.selectedFiles != null) { this.currentDocument.filename = this.selectedFiles.item(0).name; }
    this.checkFileUniqueness();
    this.showDuplicateFileMessage = !this.filenameUnique;
    this.useNewFileName = !this.filenameUnique;
  }
  selectFile(event) {
    this.selectedFiles = event.target.files;
    this.currentDocument.filename = this.selectedFiles.item(0).name;
    
    //check uniqueness
    this.checkFileUniqueness();
    //toggle visibility of non-unique-warning message and new name input
    this.showDuplicateFileMessage = !this.filenameUnique;
    this.useNewFileName = !this.filenameUnique;
  }
  checkFileUniqueness() {
    //console.log("currentDocument jobnumber when checkFileUni.. is called:", this.currentDocument.jobnumber);
    this.checkInProgress = true;
    //now we need to make sure we have the correct folder prefix
    //eventually we may want to refactor this task...
    this.currentDocument.folder_prefix = "americanpattern/";
    if(
      this.currentDocument.jobnumber != null &&
      this.currentDocument.jobnumber != ""
    ) {
      this.currentDocument.folder_prefix += this.currentDocument.jobnumber + "/";
    }
    //console.log('folder prefix set:',this.currentDocument.folder_prefix);
    //console.log('filename set:',this.currentDocument.filename);
    var uniqueName = true;
    if(this.documents != null) {
      this.documents.forEach(doc => {
        if(
          doc.filename == this.currentDocument.filename && 
          (doc.folder_prefix == this.currentDocument.folder_prefix)
        ) { 
          uniqueName = false;
          //For reference in this comment, let folder_prefix = 'americanpattern/{jobno}/':
          //Here, we update the id and comments to reflect the conflicting document.
          //We do NOT update the jobnumber, because, (although the jobnumber should 
          //be the same as jobno) if for some reason jobnumber!=jobno, we still
          //allow the user to change the job number, thus correcting this difference in the case
          //of an older file overwrite. In the case of a new file with a name change, we still don't
          //want the job number to change after the user selects it (e.g. attempt to select TC1 and 
          //input switches to TC2 when checkFileUniqueness() is called).
          //This applies farther below as well, when checking for uniqueness against the database.
          this.currentDocument.id = doc.id;
          this.currentDocument.comments = doc.comments;
        }
      });
    }

    this.filenameUnique = uniqueName;
    if(uniqueName) {
      //in case the user has selected a document, then selected another before uploading:
      //we want to make sure the document id is set to null
      this.currentDocument.id = null;
      //console.log('document sent to api for check:',this.currentDocument);
      this.uploadService.singleFileMetadata(this.currentDocument).subscribe(theDoc => {
        if(theDoc != null && theDoc.length > 0) {
          this.currentDocument.id = theDoc[0].id;
          this.currentDocument.comments = theDoc[0].comments;
          this.filenameUnique = false;
        } else {
          this.filenameUnique = true;
        }


        if(this.checkInQueue) {
          this.checkInQueue = false;
          this.checkFileUniqueness();
        } else {
          this.checkInProgress = false;
        }
        //console.log('filename unique (inside subscription):',this.filenameUnique);
      });
    } else {
      if(this.checkInQueue) {
        this.checkInQueue = false;
        this.checkFileUniqueness();
      } else {
        this.checkInProgress = false;
      }
    }

    
    //console.log('filename unique (outside subscription):',this.filenameUnique);
  }
  checkNewNameUnique() {
    //console.log('check in progress:', this.checkInProgress);

    if(this.currentDocument.filename != "" && this.currentDocument.filename != null) {
      if(!this.checkInProgress) {
        this.checkFileUniqueness();
        this.showDuplicateFileMessage = !this.filenameUnique;
      } else {
        this.checkInQueue = true;
      }
    } else {
      this.showDuplicateFileMessage = true;
    }
  }
  prevPage() {
    if(this.currentPageIndex > 0) {
      this.currentPageIndex -= 1;
      this.setView();
    }
  }
  nextPage() {
    if(this.currentPageIndex + 1 < this.pageCount) {
      this.currentPageIndex += 1;
      this.setView();
    }
  }
  setView() {
    //console.log('execute setView()')
    if(this.searchResults) {
      //console.log('searchResults not empty so we complete all the logic for setView');
      //console.log('by the way, check out searchResults:');
      //console.log(this.searchResults);
      this.displayed = [];
      this.matchMatrix = [];
      this.showCount = Math.min(this.requestCount, this.searchResults.length);
      this.pageCount = this.showCount == 0 ? 0 : Math.ceil(this.searchResults.length/this.showCount);
      this.currentPageIndex = Math.min(this.currentPageIndex, Math.max(this.pageCount - 1, 0));
      for (
        let i = this.currentPageIndex * this.showCount; 
        i < this.currentPageIndex * this.showCount + Math.min(this.searchResults.length - this.currentPageIndex * this.showCount, this.requestCount); 
        i++
      ) {
        this.displayed.push(this.searchResults[i]);
      }
      //console.log(this.displayed);
    } else {
      //console.log('searchResults empty so we must execute search()');
      this.search();
    }
  }
  preparePage() {
    //console.log("execute preparePage()");
    //console.log("preparePage() calls setView()");
    this.setView();
    //console.log("within preparePage(), setView() is done running. We now determine displayed_columns");
    if(this.displayed && this.displayed_columns.length == 0) {
      for (let prop in this.displayed[0]) {
        if(
          //the following is a list of columns which should NOT be included for display...
          !['id','company_id','folder_prefix'].includes(prop)
        ) {
          this.displayed_columns.push(prop);
        }
      }
    }
    if(!this.loaded) { this.onResize(null); }
    this.loaded = true;
  }
  @HostListener('window:resize', ['$event']) onResize(event) {
    //calibrate local tableWidth and browserWidth variables
    this.browserWidth = window.innerWidth;
    this.tableWidth = this.table == null ? this.browserWidth - 30 : this.table.nativeElement.offsetWidth;
    
    const diff = this.browserWidth - this.tableWidth;
    
    if(diff >= 30 && this.lastResize !== -1) {
      //add a column if possible
      if(this.hidden_columns.length > 0) { this.displayed_columns.push(this.hidden_columns.pop()); }
      //record lastResize
      this.lastResize = 1;
      //call self
      setTimeout(() => {
        this.onResize(event);
      },10);
    } else if(diff < 30) {
      //remove a column if possible/desired
      if(this.displayed_columns.length > 2) { this.hidden_columns.push(this.displayed_columns.pop()); }
      //record lastResize
      this.lastResize = -1;
      //call self
      setTimeout(() => {
        this.onResize(event);
      },10);
    } else {
      //reset lastResize
      this.lastResize = 0;
      //remove a column to make room for the "More ..." column
      if(this.hidden_columns && this.displayed_columns) {
        this.hidden_columns.push(this.displayed_columns.pop());
      }
    }

    if(this.lastModal != null) {
      if(document.getElementById('closeMore' + this.lastModal) != null) {
        document.getElementById('closeMore' + this.lastModal).click();
      }
    }
  }
  search() {
    //console.log('execute search()');
    if(this.documents) {
      //console.log('documents not empty so we proceed...');
      if(this.searchbar) {
        //console.log('searchbar not empty so we go through documents and populate searchResults');
        this.searchResults = [];
        this.highlightMatrix = [];
        for (let i = 0; i < this.documents.length; i++) {
          let include: boolean = false;
          let highlightRow: any = {};
          ////console.log(JSON.stringify(this.documents[i]));
          for (let prop in this.documents[i]) {
            if(this.documents[i][prop] != null) {
              if(this.documents[i][prop].toString().toLowerCase().includes(this.searchbar.toLowerCase())) {
                //row-column contains search element
                include = true;
                highlightRow[prop] = true;
              } else {
                highlightRow[prop] = false;
              }
            } else {
              highlightRow[prop] = false;
            }
          }
          if(include) {
            this.searchResults.push(this.documents[i]);
            this.highlightMatrix.push(highlightRow);
          }
        }
      } else {
        //console.log('searchbar is empty so we set searchResults to documents');
        this.searchResults = this.documents;
        this.highlightMatrix = [];
      }
      this.currentPageIndex = 0;
      //console.log('search() is now ready to call setView()');
      this.setView();
    } else {
      //console.log('documents empty so nothing happens.');
    }
  }

  matchHidden(index) {
    if(this.matchMatrix.length > index) {
      for (let i = 0; i < this.hidden_columns.length; i++) {
        if(this.matchMatrix[index][this.hidden_columns[i]]) {
          return true;
        }
      }
    }
    return false;
  }

  sortResults(property) {
    let asc = true;
    if(!this.searchResults) {
      this.search();
    }
    if(this.sortHistory[property] != null) {
      asc = this.sortHistory[property];
    } else {
      Object.defineProperty(this.sortHistory, property, {
        value: asc,
        writable: true
      });
    }
    this.searchResults = this.searchResults.sort((a,b) => {
      if(a[property] < b[property]) { 
        return asc ? -1 : 1;
      } else if (a[property] > b[property]) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    });
    this.sortHistory[property] = !asc;
    this.currentPageIndex = 0;
    this.setView();
  }
  onSubmit({value, valid}: {value: any, valid: boolean}) {
    //close the modal popup
    document.getElementById('closeUploadDocumentModal').click();
    //send create/update request
    if(valid) {
      //set the input
      var inputValues = {
        id: null,
        company_id: 2,
        folder_prefix: "americanpattern/",
        filename: "",
        jobnumber: "",
        comments: ""
      }
      inputValues.id = this.currentDocument.id;
      inputValues.filename = this.currentDocument.filename;
      inputValues.jobnumber = this.currentDocument.jobnumber;

      //we want the folder_prefix to ALWAYS be americanpattern/{jobnumber}/
      //so we handle that property separately
      if(value.jobnumber != null && value.jobnumber != "") {
        inputValues.folder_prefix += value.jobnumber +"/";
      }
      //and now we set all other properties
      for(let prop in value) {
        if(prop != 'folder_prefix') { inputValues[prop] = value[prop]; }
      }

      if(this.formNew) {
        //upload file to s3 bucket
        var uploadSuccess = this.upload(this.currentDocument.jobnumber);
        if(uploadSuccess) {
          //add display representation of metadata
          this.documents.unshift(inputValues);
        }
      } else {
        let i = 0;
        this.documents.forEach(doc => {
          if(doc.id === this.currentDocument.id) {
            this.documents.splice(i,1,inputValues);
          }
          i++;
        });
      }
      //reset searchResults and prepare data for page viewing
      delete this.searchResults;
      this.setView();

      //request the create/update from the service
      if(this.formNew) {
        if(uploadSuccess) {
          this.metadata();
        }
      } else {
        this.metadata();
      }
      
      //reset form
      //if(this.formNew) { this.blankDocumentForm(); }
    }
  }
  testfunc() {
    //console.log('loaded:',this.loaded);
    //console.log('displayed:',this.displayed);
    //console.log('documents:',this.documents);
  }
  metadata() {
    this.uploadService.addMetadata(this.currentDocument).subscribe(theDocument => {
      delete this.searchResults;
      this.uploadService.serveDocument(theDocument[0],true);
      this.documents = this.uploadService.documents;
      this.setView();
    });
  }
  deleteDocument() {
    //close the modal popup
    document.getElementById('closeDeleteDocumentModal').click();
    //request a delete from s3
    var deleteSuccess = this.uploadService.deleteFile(this.currentDocument.filename,this.currentDocument.jobnumber);

    //remove the file's metadata from the list if delete from s3 was successful
    if(deleteSuccess) {
      let i = 0;
      this.documents.forEach(doc => {
        if(doc.id === this.currentDocument.id) {
          this.documents.splice(i,1);
        } else {
          i++;
        }
      });
    }
    
    //reset searchResults and prepare data for page viewing
    delete this.searchResults;
    this.setView();

    //request a deletion of document metadata from the service if S3 deletion successful
    this.uploadService.deleteMetadata(this.currentDocument.id as number).subscribe(docs => {
      this.uploadService.documents = docs;
      this.documents = this.uploadService.documents;

      //reset searchResults and prepare data for page viewing
      delete this.searchResults;
      this.setView();
    });
    //reset currentDocument
    this.blankDocumentForm();
  }
  filterJobs() {
    this.matchNotFoundMsg = true;
    if(!this.filterInProgress) {
      this.filterInProgress = true;
      this.filteredJobs = [];
      if(this.currentDocument.jobnumber != null) {
        this.jobs.forEach((job) => {
          if(
            job.jobno.toString().toLowerCase().includes(this.currentDocument.jobnumber.toLowerCase())
          ) {
            this.filteredJobs.push(job);
          }
        });
      }
      this.filterInProgress = false;
    } else {
      if(this.filterInQueue) { this.filterJobs(); }
      this.filterInQueue = !this.filterInQueue;
    }
    //console.log('matchNotFoundMsg:',this.matchNotFoundMsg);
    setTimeout(() => {
      this.matchNotFoundMsg = false;
      //console.log('matchNotFoundMsg:',this.matchNotFoundMsg);
    },1000);
    //console.log('filteredJobs.length:',this.filteredJobs.length);
    
  }
  refreshForm() {
    this.formNew=true;
    this.selectedFiles = undefined;
    this.filenameUnique = undefined;
    this.blankDocumentForm();
  }

  blankDocumentForm() {
    this.currentDocument = {
      id: null,
      company_id: 2,
      folder_prefix: "americanpattern/",
      filename: "",
      jobnumber: "",
      comments: ""
    };
    this.useNewFileName = false;
    this.showDuplicateFileMessage = false;
    if(this.fileSelectionInput != null) {
      this.fileSelectionInput.nativeElement.value = "";
    }
    if(this.documentForm != null) {
      this.documentForm.reset();
    }
  }
  getSignedUrl() {
    this.signedUrl = this.uploadService.getFileUrl(this.currentDocument.filename,this.currentDocument.jobnumber);
  }
}
