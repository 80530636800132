import { Component, OnInit } from '@angular/core';
import { DetailService } from '../../services/detail.service';
import { Detail } from '../../models/Detail';

@Component({
  selector: 'app-machineshop',
  templateUrl: './machineshop.component.html',
  styleUrls: ['./machineshop.component.css']
})
export class MachineShopComponent implements OnInit {

  // Properties
  machineshops: Detail[];
  displayed: Detail[];
  searchResults: Detail[];
  showCount: number = 24;
  requestCount: number = 24;
  currentPageIndex: number = 0;
  pageCount: number;
  data: any;
  loaded: boolean = false;
  private checkInQueue: boolean = false;
  formNew: boolean = true;
  currentMachineShop: Detail;
  searchbar: string;

  constructor(private machineshopService: DetailService) {}


  ngOnInit() { // use this to fill properties from ajax call to server etc.
    
    this.blankMachineShopForm();

    this.machineshops = JSON.parse(localStorage.getItem('machineshops'));
    if(this.machineshops) {
      this.setView();
      this.loaded = true;
    }

    this.machineshopService.getDetails("machineshop").subscribe(machineshops => {
      this.machineshops = machineshops;
      localStorage.setItem('machineshops', JSON.stringify(this.machineshops));
      this.setView();
      this.loaded = true;
    });

  }
  prevPage() {
    if(this.currentPageIndex > 0) {
      this.currentPageIndex -= 1;
      this.setView();
    }
  }
  nextPage() {
    if(this.currentPageIndex + 1 < this.pageCount) {
      this.currentPageIndex += 1;
      this.setView();
    }
  }
  setView() {
    //console.log('execute setView()')
    if(this.searchResults) {
      //console.log('searchResults not empty so we complete all the logic for setView');
      //console.log('by the way, check out searchResults:');
      //console.log(this.searchResults);
      this.displayed = [];
      this.showCount = Math.min(this.requestCount, this.searchResults.length);
      this.pageCount = this.showCount == 0 ? 0 : Math.ceil(this.searchResults.length/this.showCount);
      this.currentPageIndex = Math.min(this.currentPageIndex, Math.max(this.pageCount - 1, 0));
      for (
        let i = this.currentPageIndex * this.showCount; 
        i < this.currentPageIndex * this.showCount + Math.min(this.searchResults.length - this.currentPageIndex * this.showCount, this.requestCount); 
        i++
      ) {
        this.displayed.push(this.searchResults[i]);
      }
      //console.log(this.displayed);
    } else {
      //console.log('searchResults empty so we must execute search()');
      this.search();
    }
  }

  search() {
    //console.log('execute search()');
    if(this.machineshops) {
      //console.log('machineshops not empty so we proceed...');
      if(this.searchbar) {
        //console.log('searchbar not empty so we go through machineshops and populate searchResults');
        
        this.searchResults = [];
        for (let i = 0; i < this.machineshops.length; i++) {
          if(this.machineshops[i].name.toString().toLowerCase().includes(this.searchbar.toLowerCase())) {
            this.searchResults.push(this.machineshops[i]);
          }
        }
      } else {
        //console.log('searchbar is empty so we set searchResults to machineshops');
        this.searchResults = this.machineshops;
      }
      //this.currentPageIndex = 0;
      //console.log('search() is now ready to call setView()');
      this.setView();
    }
  }

  onSubmit({value, valid}: {value: Detail, valid: boolean}) {
    //close the modal popup
    document.getElementById('closeaddEditMachineShopModal').click();
    //send create/update request
    if(valid) {
      if(this.formNew) {
        this.machineshops.unshift(this.currentMachineShop);
      } else {
        let i = 0;
        this.machineshops.forEach((machineshop) => {
          if(machineshop.id === this.currentMachineShop.id) {
            this.machineshops.splice(i,1,{
                id: this.currentMachineShop.id,
                company_id: this.currentMachineShop.company_id,
                name: this.currentMachineShop.name
            });
          }
          i++;
        });
      }
      //reset searchResults and prepare data for page viewing
      delete this.searchResults;
      this.setView();

      //request the create/update from the service
      this.machineshopService.addEditDetail("machineshop", {
        id:this.currentMachineShop.id,
        company_id:this.currentMachineShop.company_id,
        name:this.currentMachineShop.name
      } as Detail).subscribe(machineshops => {
        this.machineshops = machineshops;
        delete this.searchResults;
        localStorage.setItem('machineshops', JSON.stringify(this.machineshops));
        this.setView();
      });
      //reset form
      if(this.formNew) { this.blankMachineShopForm(); }
    }
  }

  deleteMachineShop() {
    //close the modal popup
    document.getElementById('closeDeleteMachineShopModal').click();
    //remove the machineshop from the list
    let i = 0;
    this.machineshops.forEach((machineshop) => {
      if(machineshop.id === this.currentMachineShop.id) {
        this.machineshops.splice(i,1);
      } else {
        i++;
      }
    });
    //reset searchResults and prepare data for page viewing
    delete this.searchResults;
    this.setView();

    //request a machineshop deletion from the service
    this.machineshopService.deleteDetail("machineshop", this.currentMachineShop.id as number).subscribe(machineshops => {
      this.machineshops = machineshops;
      localStorage.setItem('machineshops', JSON.stringify(this.machineshops));

      //reset searchResults and prepare data for page viewing
      delete this.searchResults;
      this.setView();
    });
    //reset currentMachineShop
    this.blankMachineShopForm();
  }

  refreshForm() {
    this.formNew=true;
    this.blankMachineShopForm();
  }

  blankMachineShopForm() {
    this.currentMachineShop = {
      id: null,
      company_id: 2,
      name: ""
    };
  }

}
