import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { Session } from '../../models/Session';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  isLoggedIn: boolean;
  session: Session;
  username: string;
  //adminpages
  adminpages: any[] = [
    {
      routerLink: "/users",
      titleIcon: "fa fa-users",
      titleText: "Users"
    },
    {
      routerLink: "/contacts",
      titleIcon: "fa fa-address-book",
      titleText: "Contacts"
    },
    {
      routerLink: "/paint",
      titleIcon: "fa fa-paint-brush",
      titleText: "Paint"
    },
    {
      routerLink: "/irontype",
      titleIcon: "fa fa-barcode",
      titleText: "Iron Type"
    },
    {
      routerLink: "/machineshops",
      titleIcon: "fa fa-building",
      titleText: "Machine Shop"
    },
    {
      routerLink: "/descriptions",
      titleIcon: "fa fa-edit",
      titleText: "Job Desc"
    },
    {
      routerLink: "/tickets",
      titleIcon: "fa fa-truck",
      titleText: "Ship Tickets"
    },
    {
      routerLink: "/documents",
      titleIcon: "fa fa-file",
      titleText: "Documents"
    }
  ];
  castingspages: any[] = [
    {
      routerLink: "/castings/open",
      titleIcon: "fa fa-calendar",
      titleText: "Currently Open"
    },
    {
      routerLink: "/castings/archive",
      titleIcon: "fa fa-archive",
      titleText: "Completed"
    },
    {
      routerLink: "/castings",
      titleIcon: "fa fa-bars",
      titleText: "All Jobs"
    }
  ];

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.username = localStorage.getItem('username');
    if(this.username == null) {
      /* immediately following the submit of the login component, the navbar component
       * will load before currentUser is set in localStorage
       * in this case we must subscribe to this.authService.loggedUser$ observable
       * to find the session info
       */
      this.authService.loggedUser$.subscribe(username => {
        this.username = username;
      });
    }
    this.authService.loggedState$.subscribe(state => {
      this.isLoggedIn = state;
    });
    if(this.isLoggedIn == null) {
      this.isLoggedIn = this.authService.isLoggedIn();
    }
  }

  logout() {
    this.authService.logUserOut().subscribe(); //m => console.log(m)
    this.isLoggedIn = false;
    this.router.navigate(['/login']);
  }
}
