import { Component, OnInit } from '@angular/core';
import { DetailService } from '../../services/detail.service';
import { Detail } from '../../models/Detail';

@Component({
  selector: 'app-paint',
  templateUrl: './paint.component.html',
  styleUrls: ['./paint.component.css']
})
export class PaintComponent implements OnInit {

  // Properties
  paints: Detail[];
  displayed: Detail[];
  searchResults: Detail[];
  showCount: number = 24;
  requestCount: number = 24;
  currentPageIndex: number = 0;
  pageCount: number;
  data: any;
  loaded: boolean = false;
  private checkInQueue: boolean = false;
  formNew: boolean = true;
  currentPaint: Detail;
  searchbar: string;

  constructor(private paintService: DetailService) {}


  ngOnInit() { // use this to fill properties from ajax call to server etc.
    
    this.blankPaintForm();

    this.paints = JSON.parse(localStorage.getItem('paints'));
    if(this.paints) {
      this.setView();
      this.loaded = true;
    }

    this.paintService.getDetails("paint").subscribe(paints => {
      this.paints = paints;
      localStorage.setItem('paints', JSON.stringify(this.paints));
      this.setView();
      this.loaded = true;
    });

  }
  prevPage() {
    if(this.currentPageIndex > 0) {
      this.currentPageIndex -= 1;
      this.setView();
    }
  }
  nextPage() {
    if(this.currentPageIndex + 1 < this.pageCount) {
      this.currentPageIndex += 1;
      this.setView();
    }
  }
  setView() {
    //console.log('execute setView()')
    if(this.searchResults) {
      //console.log('searchResults not empty so we complete all the logic for setView');
      //console.log('by the way, check out searchResults:');
      //console.log(this.searchResults);
      this.displayed = [];
      this.showCount = Math.min(this.requestCount, this.searchResults.length);
      this.pageCount = this.showCount == 0 ? 0 : Math.ceil(this.searchResults.length/this.showCount);
      this.currentPageIndex = Math.min(this.currentPageIndex, Math.max(this.pageCount - 1, 0));
      for (
        let i = this.currentPageIndex * this.showCount; 
        i < this.currentPageIndex * this.showCount + Math.min(this.searchResults.length - this.currentPageIndex * this.showCount, this.requestCount); 
        i++
      ) {
        this.displayed.push(this.searchResults[i]);
      }
      //console.log(this.displayed);
    } else {
      //console.log('searchResults empty so we must execute search()');
      this.search();
    }
  }

  search() {
    //console.log('execute search()');
    if(this.paints) {
      //console.log('paints not empty so we proceed...');
      if(this.searchbar) {
        //console.log('searchbar not empty so we go through paints and populate searchResults');
        
        this.searchResults = [];
        for (let i = 0; i < this.paints.length; i++) {
          if(this.paints[i].name.toString().toLowerCase().includes(this.searchbar.toLowerCase())) {
            this.searchResults.push(this.paints[i]);
          }
        }
      } else {
        //console.log('searchbar is empty so we set searchResults to paints');
        this.searchResults = this.paints;
      }
      //this.currentPageIndex = 0;
      //console.log('search() is now ready to call setView()');
      this.setView();
    }
  }

  onSubmit({value, valid}: {value: Detail, valid: boolean}) {
    //close the modal popup
    document.getElementById('closeaddEditPaintModal').click();
    //send create/update request
    if(valid) {
      if(this.formNew) {
        this.paints.unshift(this.currentPaint);
      } else {
        let i = 0;
        this.paints.forEach((paint) => {
          if(paint.id === this.currentPaint.id) {
            this.paints.splice(i,1,{
                id: this.currentPaint.id,
                company_id: this.currentPaint.company_id,
                name: this.currentPaint.name
            });
          }
          i++;
        });
      }
      //reset searchResults and prepare data for page viewing
      delete this.searchResults;
      this.setView();

      //request the create/update from the service
      this.paintService.addEditDetail("paint", {
        id:this.currentPaint.id,
        company_id:this.currentPaint.company_id,
        name:this.currentPaint.name
      } as Detail).subscribe(paints => {
        this.paints = paints;
        delete this.searchResults;
        localStorage.setItem('paints', JSON.stringify(this.paints));
        this.setView();
      });
      //reset form
      if(this.formNew) { this.blankPaintForm(); }
    }
  }

  deletePaint() {
    //close the modal popup
    document.getElementById('closeDeletePaintModal').click();
    //remove the paint from the list
    let i = 0;
    this.paints.forEach((paint) => {
      if(paint.id === this.currentPaint.id) {
        this.paints.splice(i,1);
      } else {
        i++;
      }
    });
    //reset searchResults and prepare data for page viewing
    delete this.searchResults;
    this.setView();

    //request a paint deletion from the service
    this.paintService.deleteDetail("paint", this.currentPaint.id as number).subscribe(paints => {
      this.paints = paints;
      localStorage.setItem('paints', JSON.stringify(this.paints));

      //reset searchResults and prepare data for page viewing
      delete this.searchResults;
      this.setView();
    });
    //reset currentPaint
    this.blankPaintForm();
  }

  refreshForm() {
    this.formNew=true;
    this.blankPaintForm();
  }

  blankPaintForm() {
    this.currentPaint = {
      id: null,
      company_id: 2,
      name: ""
    };
  }

}
