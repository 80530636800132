import { Component, ViewChild, ElementRef, OnInit, HostListener } from '@angular/core';
import { ContactService } from '../../services/contact.service';
import { Contact } from '../../models/Contact';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})

export class ContactsComponent implements OnInit {
  allResults: Contact[];
  searchResults: Contact[];
  contacts: Contact[] = [];
  currentContact: Contact;
  showCount: number = 10;
  requestCount: number = 10;
  currentPageIndex: number = 0;
  pageCount: number;
  searchbar: string;
  highlightMatrix: any[] = [];
  matchMatrix: any[];
  loaded: boolean = false;
  displayed_columns: any[] = [];
  hidden_columns: any[] = [];
  hoverIndex: number;
  sortHistory = {};
  showLoading:boolean = false;
  showSuccessMessage:boolean = false;
  formNew:boolean;

  @ViewChild('contactsTable') table?: ElementRef;
  tableWidth: number;
  browserWidth: number; //this is the browser. the width of actual screen may be read with window.screen.width
  lastModal: number;
  /* var @lastResize options:
   * -1 (meaning that the last resize was to shrink the number)
   *
   */
  lastResize: number = 0;

  constructor(private contactService: ContactService) { }

  ngOnInit() {
    this.blankContactForm();
    this.allResults = JSON.parse(localStorage.getItem('allContacts'));
    if(this.allResults) {
      this.preparePage();
    }
    this.contactService.getContacts().subscribe(contacts => {
      localStorage.setItem('allContacts', JSON.stringify(contacts));
      this.allResults = contacts;
      this.preparePage();
    });
  }

  onSubmit(contact: Contact) {
    //console.log('-------------------------------------------------');
    this.showLoading = true;
    this.contactService.addEditContact({
      id:this.currentContact.id,
      company_id:this.currentContact.company_id,
      contact:this.currentContact.contact, 
      company:this.currentContact.company,
      phone:this.currentContact.phone,
      email:this.currentContact.email,
      address:this.currentContact.address
    } as Contact).subscribe(contacts => {
      this.allResults = contacts;
      delete this.searchResults;
      localStorage.setItem('allContacts', JSON.stringify(this.allResults));
      
      //console.log(this.allResults);
      this.showLoading = false;
      this.showSuccessMessage = true;
      //close the modal popup and hide the success message
      setTimeout(() => {
        //modal
        document.getElementById('closeAddEditContactModal').click();
        //message
        this.showSuccessMessage = false;
      }, 500);
      this.preparePage();
    });
  }

  editContact(contact: Contact) {
    this.formNew = false;
    this.currentContact = contact;
  }

  deleteContact(contact: Contact) {
    //close the modal popup
    document.getElementById('closeDeleteContactModal').click();
    //remove the contact from the list
    let i = 0;
    this.allResults.forEach((contact) => {
      if(contact.id === this.currentContact.id) {
        this.allResults.splice(i,1);
      } else {
        i++;
      }
    });
    //reset searchResults and prepare data for page viewing
    delete this.searchResults;
    this.preparePage();

    //request a contact deletion from the service
    this.contactService.deleteContact(this.currentContact.id as number).subscribe(contacts => {
      this.allResults = contacts;
      localStorage.setItem('allContacts', JSON.stringify(this.allResults));
    });
    //reset currentContact
    this.blankContactForm();
  }

  blankContactForm() {
    this.formNew = true;
    this.currentContact = {
      id: null,
      company_id: 2,
      contact: "",
      company: "",
      phone: "",
      email: "",
      address: ""
    };
  }

  preparePage() {
    //console.log("execute preparePage()");
    //console.log("preparePage() calls setView()");
    this.setView();
    //console.log("within preparePage(), setView() is done running. We now determine displayed_columns");
    if(this.contacts && this.displayed_columns.length == 0) {
      for (let prop in this.contacts[0]) {
        if(prop != 'id' && prop != 'company_id') {
          this.displayed_columns.push(prop);
        }
      }
    }
    if(!this.loaded) { this.onResize(null); }
    this.loaded = true;
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    //calibrate local tableWidth and browserWidth variables
    this.browserWidth = window.innerWidth;
    this.tableWidth = this.table == null ? this.browserWidth - 30 : this.table.nativeElement.offsetWidth;
    
    const diff = this.browserWidth - this.tableWidth;
    
    if(diff >= 30 && this.lastResize !== -1) {
      //add a column if possible
      if(this.hidden_columns.length > 0) { this.displayed_columns.push(this.hidden_columns.pop()); }
      //record lastResize
      this.lastResize = 1;
      //call self
      setTimeout(() => {
        this.onResize(event);
      },10);
    } else if(diff < 30) {
      //remove a column if possible/desired
      if(this.displayed_columns.length > 2) { this.hidden_columns.push(this.displayed_columns.pop()); }
      //record lastResize
      this.lastResize = -1;
      //call self
      setTimeout(() => {
        this.onResize(event);
      },10);
    } else {
      //reset lastResize
      this.lastResize = 0;
      //remove a column to make room for the "More ..." column
      if(this.hidden_columns && this.displayed_columns) {
        this.hidden_columns.push(this.displayed_columns.pop());
      }
    }

    if(this.lastModal != null) {
      if(document.getElementById('closeMore' + this.lastModal) != null) {
        document.getElementById('closeMore' + this.lastModal).click();
      }
    }
  }

  lastModalOpen(last) {
    this.lastModal = last;
  }

  setView() {
    //console.log('execute setView()')
    if(this.searchResults) {
      //console.log('searchResults not empty so we complete all the logic for setView');
      //console.log('by the way, check out searchResults:');
      //console.log(this.searchResults);
      this.contacts = [];
      this.matchMatrix = [];
      this.showCount = Math.min(this.requestCount, this.searchResults.length);
      this.pageCount = this.showCount == 0 ? 0 : Math.ceil(this.searchResults.length/this.showCount);
      for (
        let i = this.currentPageIndex * this.showCount; 
        i < this.currentPageIndex * this.showCount + Math.min(this.searchResults.length - this.currentPageIndex * this.showCount, this.requestCount); 
        i++
      ) {
        this.contacts.push(this.searchResults[i]);
        if(this.highlightMatrix.length > 0) {
          this.matchMatrix.push(this.highlightMatrix[i]);
        }
      }
    } else {
      //console.log('searchResults empty so we must execute search()');
      this.search();
    }
  }

  prevPage() {
    if(this.currentPageIndex > 0) {
      this.currentPageIndex -= 1;
      this.setView();
    }
  }
  nextPage() {
    if(this.currentPageIndex + 1 < this.pageCount) {
      this.currentPageIndex += 1;
      this.setView();
    }
  }
  search() {
    //console.log('execute search()');
    if(this.allResults) {
      //console.log('allResults not empty so we proceed...');
      if(this.searchbar) {
        //console.log('searchbar not empty so we go through allResults and populate searchResults');
        this.searchResults = [];
        this.highlightMatrix = [];
        for (let i = 0; i < this.allResults.length; i++) {
          let include: boolean = false;
          let highlightRow: any = {};
          ////console.log(JSON.stringify(this.allResults[i]));
          for (let prop in this.allResults[i]) {
            if(this.allResults[i][prop] != null) {
              if(this.allResults[i][prop].toString().toLowerCase().includes(this.searchbar.toLowerCase())) {
                //row-column contains search element
                include = true;
                highlightRow[prop] = true;
              } else {
                highlightRow[prop] = false;
              }
            }
          }
          if(include) {
            this.searchResults.push(this.allResults[i]);
            this.highlightMatrix.push(highlightRow);
          }
        }
      } else {
        //console.log('searchbar is empty so we set searchResults to allResults');
        this.searchResults = this.allResults;
        this.highlightMatrix = [];
      }
      this.currentPageIndex = 0;
      //console.log('search() is now ready to call setView()');
      this.setView();
    } else {
      //console.log('allResults empty so nothing happens.');
    }
  }

  matchHidden(index) {
    if(this.matchMatrix.length > index) {
      for (let i = 0; i < this.hidden_columns.length; i++) {
        if(this.matchMatrix[index][this.hidden_columns[i]]) {
          return true;
        }
      }
    }
    return false;
  }

  sortResults(property) {
    let asc = true;
    if(!this.searchResults) {
      this.search();
    }
    if(this.sortHistory[property] != null) {
      asc = this.sortHistory[property];
    } else {
      Object.defineProperty(this.sortHistory, property, {
        value: asc,
        writable: true
      });
    }
    this.searchResults = this.searchResults.sort((a,b) => {
      if(a[property] < b[property]) { 
        return asc ? -1 : 1;
      } else if (a[property] > b[property]) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    });
    this.sortHistory[property] = !asc;
    this.currentPageIndex = 0;
    this.setView();
  }
}
