import { Component, ViewChild, ElementRef, OnInit, HostListener } from '@angular/core';
import { TicketService } from '../../services/ticket.service';
import { Ticket } from '../../models/Ticket';
import { UploadFileService } from '../../services/upload-file.service';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css']
})

export class TicketsComponent implements OnInit {
  allResults: Ticket[];
  searchResults: Ticket[];
  tickets: Ticket[] = [];
  currentTicket: Ticket;
  showCount: number = 10;
  requestCount: number = 10;
  currentPageIndex: number = 0;
  pageCount: number;
  searchbar: string;
  highlightMatrix: any[] = [];
  matchMatrix: any[];
  loaded: boolean = false;
  displayed_columns: any[] = [];
  hidden_columns: any[] = [];
  hoverIndex: number;
  sortHistory = {};
  showLoading:boolean = false;
  showSuccessMessage:boolean = false;
  formNew:boolean;
  now: any;
  recipientTypeChanged: boolean = false;

  @ViewChild('ticketsTable') table?: ElementRef;
  tableWidth: number;
  browserWidth: number; //this is the browser. the width of actual screen may be read with window.screen.width
  lastModal: number;
  /* var @lastResize options:
   * -1 (meaning that the last resize was to shrink the number)
   *
   */
  lastResize: number = 0;

  constructor(
    private ticketService: TicketService,
    private uploadService: UploadFileService
  ) { }

  ngOnInit() {
    this.blankTicketForm();
    this.allResults = this.ticketService.tickets;
    if(this.allResults) {
      this.preparePage();
    }
    this.ticketService.getTickets().subscribe(tickets => {
      this.ticketService.setTickets(tickets);
      this.allResults = this.ticketService.tickets;
      this.preparePage();
    });
  }

  onSubmit(ticket: Ticket) {
    //console.log('-------------------------------------------------');

    this.showLoading = true;
    let saveTicket: Ticket = {};
    for(let prop in ticket) {
      if(prop == 'shipments') {
        saveTicket[prop] = JSON.stringify(ticket[prop]);
      } else {
        saveTicket[prop] = ticket[prop];
      }
    }
    this.ticketService.serveTicket(saveTicket);
    //ticket.shipments = JSON.stringify(ticket.shipments);
    //this.ticketService.serveTicket(ticket);
    delete this.searchResults;
    setTimeout(() => {
      //modal
      document.getElementById('closeAddTicketModal').click();
    }, 500);
    //console.log('we will now call preparePage()');
    this.preparePage();

    this.ticketService.addTicket(saveTicket as Ticket).subscribe(theTicket => {
      //console.log(theTicket);
      delete this.searchResults;
      this.ticketService.serveTicket(theTicket[0],true);
      this.allResults = this.ticketService.tickets;
      
      this.showLoading = false;
      this.showSuccessMessage = true;
      //close the modal popup and hide the success message
      setTimeout(() => {
        //modal
        document.getElementById('closeAddTicketModal').click();
        //message
        this.showSuccessMessage = false;
      }, 500);
      this.preparePage();
    });
  }

  preparePrint(ticket: Ticket) {
    this.formNew = false;
    this.currentTicket = {};
    for(let prop in ticket) {
      if(prop == 'shipments') {
        this.currentTicket[prop] = JSON.parse(ticket[prop]);
      } else {
        this.currentTicket[prop] = ticket[prop];
      }
    }
    this.recipientTypeChanged = false;
  }
  recipientTypeChange(bool) {
    this.recipientTypeChanged = bool;
  }
  deleteTicket(ticket: Ticket) {
    //close the modal popup
    document.getElementById('closeDeleteTicketModal').click();
    //remove the ticket from the list
    let i = 0;
    this.allResults.forEach((ticket) => {
      if(ticket.id === this.currentTicket.id) {
        this.allResults.splice(i,1);
      } else {
        i++;
      }
    });
    //reset searchResults and prepare data for page viewing
    delete this.searchResults;
    this.preparePage();
    
    //request a ticket deletion from the service
    // KEEP this for later - eventually we want the shipment info stored in only the tickets table
    // this.ticketService.deleteTicket(this.currentTicket.id as number).subscribe(tickets => {
    //   this.ticketService.setTickets(tickets);
    //   this.allResults = this.ticketService.tickets;
    // });
    //for now, we want to reference a function that will delete the shipment information in
    //both places where it is stored.
    this.ticketService.deleteTicketAndRemoveFromJob(this.currentTicket).subscribe(tickets => {
      this.ticketService.setTickets(tickets);
      this.allResults = this.ticketService.tickets;
    });

    this.uploadService.deleteFile('ship_ticket_'+ ticket.ticket +'.pdf',ticket.job);

    //request a deletion of document metadata from the service if S3 deletion successful
    this.uploadService.deleteMetadata(ticket.document).subscribe(docs => {
      this.uploadService.documents = docs;
    });
    //reset currentTicket
    this.blankTicketForm();
  }

  blankTicketForm() {
    this.formNew = true;
    this.currentTicket = {
      id: null,
      company_id: 2,
      ticket: null,
      job: "",
      purchaseorder: "",
      shipper: "",
      name: "",
      date: this.formatDate('now'),
      address: "",
      shipments: [{count:"",description:""}],
      recipient_type: null
    };
  }
  isDate(date: string): boolean {
    var dateObj = new Date(date);
    return dateObj.toLocaleDateString("en-US") !== "Invalid Date";
  }
  formatDate(date) {
    if(date === "now" || this.isDate(date)) {
      let d: any;
      if(date === "now") { 
        this.now = new Date(); 
        d = new Date();
      } else {
        d = new Date(date);
        d.setDate(d.getDate() + 1);
      }
      var month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-');
    } else {
      return "";
    }
  }
  preparePage() {
    //console.log("execute preparePage()");
    //console.log("preparePage() calls setView()");
    this.setView();
    //console.log("within preparePage(), setView() is done running. We now determine displayed_columns");
    if(this.tickets && this.displayed_columns.length == 0) {
      for (let prop in this.tickets[0]) {
        if(
          //the following is a list of columns which should NOT be included for display...
          !['id','company_id','document','job','purchaseorder','shipments','recipient_type'].includes(prop)
        ) {
          this.displayed_columns.push(prop);
        }
      }
    }
    if(!this.loaded) { this.onResize(null); }
    this.loaded = true;
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    //calibrate local tableWidth and browserWidth variables
    this.browserWidth = window.innerWidth;
    this.tableWidth = this.table == null ? this.browserWidth - 30 : this.table.nativeElement.offsetWidth;
    
    const diff = this.browserWidth - this.tableWidth;
    
    if(diff >= 30 && this.lastResize !== -1) {
      //add a column if possible
      if(this.hidden_columns.length > 0) { this.displayed_columns.push(this.hidden_columns.pop()); }
      //record lastResize
      this.lastResize = 1;
      //call self
      setTimeout(() => {
        this.onResize(event);
      },10);
    } else if(diff < 30) {
      //remove a column if possible/desired
      if(this.displayed_columns.length > 2) { this.hidden_columns.push(this.displayed_columns.pop()); }
      //record lastResize
      this.lastResize = -1;
      //call self
      setTimeout(() => {
        this.onResize(event);
      },10);
    } else {
      //reset lastResize
      this.lastResize = 0;
      //remove a column to make room for the "More ..." column
      if(this.hidden_columns && this.displayed_columns) {
        this.hidden_columns.push(this.displayed_columns.pop());
      }
    }

    if(this.lastModal != null) {
      if(document.getElementById('closeMore' + this.lastModal) != null) {
        document.getElementById('closeMore' + this.lastModal).click();
      }
    }
  }

  lastModalOpen(last) {
    this.lastModal = last;
  }

  setView() {
    //console.log('execute setView()')
    if(this.searchResults) {
      //console.log('searchResults not empty so we complete all the logic for setView');
      //console.log('by the way, check out searchResults:');
      //console.log(this.searchResults);
      this.tickets = [];
      this.matchMatrix = [];
      this.showCount = Math.min(this.requestCount, this.searchResults.length);
      this.pageCount = this.showCount == 0 ? 0 : Math.ceil(this.searchResults.length/this.showCount);
      for (
        let i = this.currentPageIndex * this.showCount; 
        i < this.currentPageIndex * this.showCount + Math.min(this.searchResults.length - this.currentPageIndex * this.showCount, this.requestCount); 
        i++
      ) {
        this.tickets.push(this.searchResults[i]);
        if(this.highlightMatrix.length > 0) {
          this.matchMatrix.push(this.highlightMatrix[i]);
        }
      }
    } else {
      //console.log('searchResults empty so we must execute search()');
      this.search();
    }
  }

  prevPage() {
    if(this.currentPageIndex > 0) {
      this.currentPageIndex -= 1;
      this.setView();
    }
  }
  nextPage() {
    if(this.currentPageIndex + 1 < this.pageCount) {
      this.currentPageIndex += 1;
      this.setView();
    }
  }
  search() {
    //console.log('execute search()');
    if(this.allResults) {
      //console.log('allResults not empty so we proceed...');
      if(this.searchbar) {
        //console.log('searchbar not empty so we go through allResults and populate searchResults');
        this.searchResults = [];
        this.highlightMatrix = [];
        for (let i = 0; i < this.allResults.length; i++) {
          let include: boolean = false;
          let highlightRow: any = {};
          ////console.log(JSON.stringify(this.allResults[i]));
          for (let prop in this.allResults[i]) {
            if(this.allResults[i][prop].toString().toLowerCase().includes(this.searchbar.toLowerCase())) {
              //row-column contains search element
              include = true;
              highlightRow[prop] = true;
            } else {
              highlightRow[prop] = false;
            }
          }
          if(include) {
            this.searchResults.push(this.allResults[i]);
            this.highlightMatrix.push(highlightRow);
          }
        }
      } else {
        //console.log('searchbar is empty so we set searchResults to allResults');
        this.searchResults = this.allResults;
        this.highlightMatrix = [];
      }
      this.currentPageIndex = 0;
      //console.log('search() is now ready to call setView()');
      this.setView();
    } else {
      //console.log('allResults empty so nothing happens.');
    }
  }

  matchHidden(index) {
    if(this.matchMatrix.length > index) {
      for (let i = 0; i < this.hidden_columns.length; i++) {
        if(this.matchMatrix[index][this.hidden_columns[i]]) {
          return true;
        }
      }
    }
    return false;
  }

  sortResults(property) {
    let asc = true;
    if(!this.searchResults) {
      this.search();
    }
    if(this.sortHistory[property] != null) {
      asc = this.sortHistory[property];
    } else {
      Object.defineProperty(this.sortHistory, property, {
        value: asc,
        writable: true
      });
    }
    if(this.searchResults != null) {
      this.searchResults = this.searchResults.sort((a,b) => {
        if(a[property] < b[property]) { 
          return asc ? -1 : 1;
        } else if (a[property] > b[property]) {
          return asc ? 1 : -1;
        } else {
          return 0;
        }
      });   
    }
    this.sortHistory[property] = !asc;
    this.currentPageIndex = 0;
    this.setView();
  }
}
