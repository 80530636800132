import { NgModule, OnInit } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { UsersComponent } from './components/users/users.component';
import { CastingsComponent } from './components/castings/castings.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { IronTypeComponent } from './components/irontype/irontype.component';
import { MachineShopComponent } from './components/machineshop/machineshop.component';
import { PaintComponent } from './components/paint/paint.component';
import { JobDescComponent } from './components/jobdescription/jobdescription.component';
import { LoginComponent } from './components/login/login.component';
import { TicketsComponent } from './components/tickets/tickets.component';
import { DocumentsComponent } from './components/documents/documents.component';

import { AuthService } from './services/auth.service';
import { AuthGuardService as AuthGuard } from './services/authguard.service';

const routes: Routes = [
  {path: 'home', component: HomeComponent, canActivate:[AuthGuard]},
  {path: '', redirectTo: 'home', pathMatch: 'full', canActivate:[AuthGuard]},
  {path: 'users', component: UsersComponent, canActivate:[AuthGuard], data: {roles: ['Full Permission']}},
  {path: 'castings', component: CastingsComponent, canActivate:[AuthGuard], data: {roles: ['Full Permission']}},
  {path: 'castings/open', component: CastingsComponent, canActivate:[AuthGuard]},
  {path: 'castings/archive', component: CastingsComponent, canActivate:[AuthGuard], data: {roles: ['Full Permission']}},
  {path: 'contacts', component: ContactsComponent, canActivate:[AuthGuard]},
  {path: 'paint', component: PaintComponent, canActivate:[AuthGuard]},
  {path: 'irontype', component: IronTypeComponent, canActivate:[AuthGuard]},
  {path: 'machineshops', component: MachineShopComponent, canActivate:[AuthGuard]},
  {path: 'descriptions', component: JobDescComponent, canActivate:[AuthGuard]},
  {path: 'tickets', component: TicketsComponent, canActivate:[AuthGuard]},
  {path: 'documents', component: DocumentsComponent, canActivate:[AuthGuard]},
  
  {path: 'login', component: LoginComponent},
  {path: '**', component: HomeComponent, canActivate:[AuthGuard]}
];

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(routes)
  ],
  providers: [AuthService]
})
export class AppRoutingModule implements OnInit {
  constructor(private authService: AuthService) { }
  ngOnInit() {

  }

}
