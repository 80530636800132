import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { MessageService } from '../../services/message.service';
import { Login } from '../../models/Login';
import { Session } from '../../models/Session';
import { Message } from '../../models/Message';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  session: any;
  username: string;
  password: string;
  loginattempt: string = "xKcdR2d2c3p0";
  messages: Message = {
    has_message: {
      errors: [],
      info: [],
      success: []
    }
  };

  constructor(
    private loginService: AuthService,
    private messageService: MessageService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loginService.getSession().subscribe(session => {
      this.loginService.session = session;
      this.session = session;
      console.log('Response from API - session variable placeholder',this.session);
    });
    if(this.loginService.isLoggedIn()) {
      //console.log('user is already authenticated');
      this.router.navigate(['/']);
    }
  }
  
  onSubmit() {
    this.loginService.logUserIn({
      username:this.username, 
      password:this.password, 
      loginattempt:this.loginattempt
    } as Login).subscribe(session => {
      if(this.loginService.isSession(session)) {
        this.loginService.session = session;
        localStorage.setItem('username', session.username);
        localStorage.setItem('active', 'true');
        /* we set the logged state to true so that the navbar component knows about the
         * login without needing to be reloaded
         */
        this.loginService.setLoggedState(true);
        this.loginService.setLoggedUser(session.username);
        //now that the login process is complete, navigate to 'home'
        this.router.navigate(['/']);
      }
      if(this.messageService.containsMessage(session)) {
        this.messages.has_message = session.has_message;
      }
    });
  }

}
