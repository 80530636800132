import { Component, OnInit, EventEmitter, Output, Input, ElementRef, ViewChild } from '@angular/core';
import { TicketService } from '../../services/ticket.service';
import { CastingService } from '../../services/casting.service';
import { Ticket } from '../../models/Ticket';
import { Contact } from '../../models/Contact';
import * as jsPDF from 'jspdf';
import * as html2canvas from 'html2canvas';

//imports to attempt auto-upload to s3
import { UploadFileService } from '../../services/upload-file.service';
import { AnyLengthString } from 'aws-sdk/clients/comprehend';
import { ContactService } from '../../services/contact.service';

@Component({
  selector: 'app-ticket-form',
  templateUrl: './ticket-form.component.html',
  styleUrls: ['./ticket-form.component.css']
})
export class TicketFormComponent implements OnInit {

  //properties
  @Input() currentTicket: Ticket;
  @Input() formNew: boolean;
  @Input() showLoading: boolean;
  @Input() showSuccessMessage: boolean;
  @Input() recipientTypeChanged: boolean;
  @Output() addTicket: EventEmitter<Ticket> = new EventEmitter();
  @Output() ticketDeletion: EventEmitter<Ticket> = new EventEmitter();
  @Output() recipientTypeChangedEvent: EventEmitter<boolean> = new EventEmitter();

  jobs: any[];
  contacts: Contact[];
  filteredJobs: any[];
  filteredContacts: Contact[];
  filterInProgress: boolean = false;
  filterInQueue: boolean = false;
  private ticketUnique: boolean;
  private uniqueCheckComplete: boolean;
  private checkInQueue: boolean = false;
  autogenTicket: boolean = false;
  invalidChars: boolean = false;
  totalQuantity: number = 0;
  jobShipmentRecord: any = {
    count: 0,
    date: ''
  };
  

  @ViewChild('content') content: ElementRef
  constructor(
    private ticketService: TicketService,
    private jobService: CastingService,
    private uploadService: UploadFileService,
    private contactService: ContactService
  ) {}

  ngOnInit() {
    this.jobs = this.jobService.lastCast('open');
    if(this.jobs != null) {
      this.filteredJobs = this.jobs;
    } else {
      this.jobService.getCastings('all').subscribe(jobs => {
        this.jobService.setCast(jobs);
        this.jobs = this.jobService.lastCast('open');
        this.filteredJobs = this.jobs;
      });
    }
    this.contacts = this.contactService.contacts;
    this.contactService.getContacts().subscribe(contacts => {
      this.contactService.setContacts(contacts);
      this.contacts = this.contactService.contacts;
    });
    this.jobShipmentRecord.date = this.currentTicket.date;
  }
  test() { //debugging messages
    //console.log(this.recipientTypeChanged);
  }
  onSubmit({value, valid}: {value: Ticket, valid: boolean}) {
    //send create/update request
    if(valid) { 
      if((this.ticketUnique && this.uniqueCheckComplete) || this.autogenTicket) {
        if(this.autogenTicket) {
          this.currentTicket.ticket = "";
          this.autogenTicket = false;
        }
        this.addTicket.emit(this.currentTicket);
        //link the total quantity & date info to the job number if requested
        if(this.currentTicket.recipient_type > 0) {
          this.jobService.addShipment({
            action: this.currentTicket.recipient_type,
            data: this.jobShipmentRecord,
            job: this.currentTicket.job,
            dateupdated: this.formatDate("now")
          }).subscribe(theJob => {
            if(theJob != null) {
              //console.log('one more test:',theJob[0]);
              this.jobService.prepareAndEditCast(theJob[0]);
              //we need to emit something here so that casting form gets updated if user makes a quick switch
            }
          });
          this.totalQuantity=0;
        }
      }
    }
  }
  emitRecipientTypeChange(has_changed: boolean) {
    this.recipientTypeChangedEvent.emit(has_changed);
  }
  isDate(date: string): boolean {
    var dateObj = new Date(date);
    return dateObj.toLocaleDateString("en-US") !== "Invalid Date";
  }
  formatDate(date) {

    if(date === "now" || this.isDate(date)) {
      let d: any;
      if(date === "now") {
        d = new Date();
      } else {
        d = new Date(date);
        d.setDate(d.getDate() + 1);
      }
      var month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-');
    } else {
      return "";
    }
  }
  deleteTicket() {
    this.ticketDeletion.emit(this.currentTicket);
  }

  // test(obj) {
  //   console.log(obj.errors.required);
  //   console.log(!this.ticketUnique && this.uniqueCheckComplete && !this.autogenTicket);
  // }
  filterContacts() {
    if(!this.filterInProgress) {
      this.filterInProgress = true;
      this.filteredContacts = [];
      if(this.contacts != null) {
        this.contacts.forEach((contact) => {
          if(
            contact.contact.toString().toLowerCase().includes(this.currentTicket.name.toLowerCase()) ||
            contact.company.toString().toLowerCase().includes(this.currentTicket.name.toLowerCase()) ||
            contact.phone.toString().toLowerCase().includes(this.currentTicket.name.toLowerCase())
          ) {
            this.filteredContacts.push(contact);
          }
        });        
      }
      this.filterInProgress = false;
    } else {
      if(this.filterInQueue) { this.filterContacts(); }
      this.filterInQueue = !this.filterInQueue;
    }
  }
  fillEmptyFromContact(contact: Contact) {
    this.currentTicket.name = contact.contact + "; " + contact.company;
    if(
      (this.currentTicket.address == null || this.currentTicket.address == "") &&
      (contact.address != null && contact.address != "")
    ) {
      this.currentTicket.address = contact.address;
    }
  }
  filterJobs() {
    if(!this.filterInProgress) {
      this.filterInProgress = true;
      this.filteredJobs = [];
      this.jobs.forEach((job) => {
        if(
          job.jobno.toString().toLowerCase().includes(this.currentTicket.job.toLowerCase())) {
          this.filteredJobs.push(job);
        }
      });
      this.filterInProgress = false;
    } else {
      if(this.filterInQueue) { this.filterJobs(); }
      this.filterInQueue = !this.filterInQueue;
    }
  }
  fillEmptyFromJob(job) {
    this.currentTicket.job=job.jobno;
    if(this.currentTicket.purchaseorder == "") {
      this.currentTicket.purchaseorder=job.purchaseorder;
    }
    if(this.currentTicket.shipper == "") {
      this.currentTicket.shipper=job.jobno;
    }
    if(this.currentTicket.shipments.length > 0) {
      this.currentTicket.shipments.forEach(shipment => {
        if(shipment.description == "") {
          shipment.description = job.partno;
        }
      });
    }
  }
  addShipment() {
    if(this.currentTicket.shipments.length < 13) {
      this.currentTicket.shipments.push({count: "", description: ""});
    }
  }
  updateTotalQuantity() {
    let tq = 0;
    this.currentTicket.shipments.forEach(shipment => {
      if(!isNaN(shipment.count)) {
        tq += shipment.count;
      }
    });
    this.totalQuantity = tq;
    this.jobShipmentRecord.count = tq;
  }
  upDate() {
    this.jobShipmentRecord.date = this.currentTicket.date;
  }
  downloadPDF() {
    let filename = 'ship_ticket_'+ this.currentTicket.ticket +'.pdf';
    if(this.currentTicket.document == null) { //check if there is NO document id
      //this is what we do if ticket has never been uploaded to s3
      let content = this.content.nativeElement;
      let doc = new jsPDF();
      html2canvas(content).then((canvas) => {
        
        var img = canvas.toDataURL("image/jpeg", 1.0);
        var height = 109.875;
        if(this.currentTicket.shipments != null) {
          if(this.currentTicket.shipments.length > 0) {
            this.currentTicket.shipments.forEach(() => {
              height += 11.125;
            });
          }
        }
        doc.addImage(img, 'JPEG',20,20,170,height);
        var file = doc.output('blob');
        file.name = filename;
        file.webkitRelativePath = '';
        //console.log('file:',file);

        //now we need a version of 'saveMetadataAndUploadFile':
        //saving metadata should generate a unique document id, this must be added to the ticket metadata
        doc.save(filename);
        this.saveMetadataAndUpload(file, this.currentTicket.id);
      });
    } else { //ticket ALREADY has document id...
      //this is what we do if ticket has already been uploaded to s3
      this.openFile(filename,this.currentTicket.job);
    }
  }
  saveMetadataAndUpload(file: any, ticket_id: number) {

    //NOTE: in this method, we still need to update the ticket at the service (locally)
    //to include the newly created document_id
    //the consequence of this omission is that the database is still being requested to add the 
    //document metadata for the ticket...of course, no new insert is being made because the folder_prefix/filename
    //uniqueness constraint is being violated.
    //as a result, there is no visible functionality problem, but it is still not ideal.

    if(this.currentTicket.job != null && file != null) {
      //save the requested metadata to the database
      let doc_metadata = {
        id: null,
        company_id: 2,
        folder_prefix: "",
        filename: "",
        jobnumber: "",
        comments: ""
      };
      doc_metadata.filename = file.name;
      doc_metadata.jobnumber = this.currentTicket.job;
      doc_metadata.folder_prefix = "americanpattern/" + this.currentTicket.job + "/";
      this.uploadService.addMetadataForTicket(doc_metadata, ticket_id as number).subscribe(theDocument => {
        if(theDocument != null) {
          this.uploadService.serveDocument(theDocument[0],true);
          var new_doc_id = theDocument[0].id;
          if(this.upload(file,theDocument[0].jobnumber)) {
            console.log('File upload successful.');
          } else {
            //if the upload to s3 is not successful, we delete the metadata from our database
            this.uploadService.deleteMetadataForTicket(new_doc_id as number, ticket_id as number).subscribe(docs => {
              this.uploadService.documents = docs;
            });
          }
        }
      });
    }
  }
  signedUrl(filename: string, jobnumber:string): string {
    return this.uploadService.getFileUrl(filename, jobnumber);
  }
  openFile(filename: string, jobnumber: string) {
    window.open(this.signedUrl(filename,jobnumber));
  }
  upload(file,folder) {
    return this.uploadService.uploadfile(file,folder);
  }
  checkValid() {
    if(['s','S'].includes(this.currentTicket.ticket.charAt(0))) {
      this.invalidChars = true;
    } else {
      this.invalidChars = false;
      this.checkUnique();
    }
  }
  checkUnique() {
    if(this.uniqueCheckComplete || this.uniqueCheckComplete === undefined) {
      this.uniqueCheckComplete = false;
      
      if(this.formNew) {
        this.ticketService.ticketExists(
          this.currentTicket.ticket as string,
          this.currentTicket.company_id as number
        ).subscribe(dne => {
          //console.log(dne);
          this.checkUniqueBookkeeper(dne);
        });
      } else {
        this.checkUniqueBookkeeper(true);
      }
    } else {
      this.checkInQueue = true;
    }
    //console.log('error should be hidden because: HIDE? -',this.jobnoUnique || !this.uniqueCheckComplete)
  }
  checkUniqueBookkeeper(unique: boolean) {
    this.ticketUnique = unique;
    this.uniqueCheckComplete = true;
    if(this.checkInQueue) {
      this.checkInQueue = false;
      this.checkUnique();
    }
  }
}
