import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import { Subject } from 'rxjs/Subject';
import { Login } from '../models/Login';
import { User } from '../models/User';
import { Session } from '../models/Session';
import { environment } from '../../environments/environment';
// const httpOptions = {
// defaults: {
//   useXDomain: true
// }, // is this valid?
// withCredentials: true,
//   headers: new HttpHeaders({'Content-Type': 'application/json'})
// }
const httpOptions = {
  withCredentials: true,
  headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'X-API-KEY': 'insert_api_key_here'
  })
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  sessionUrl: string = environment.apiUrl+'?session';
  logoutUrl: string = environment.apiUrl+'?logout';
  loggedIn: boolean;
  session: Session;
  groups: any[] = [
    {
      id: 1,
      group_name: 'Full Permission',
      description: ''
    }
  ];

  private loggedStateSource = new Subject<boolean>();
  private loggedUserSource = new Subject<string>();

  loggedState$ = this.loggedStateSource.asObservable();
  loggedUser$ = this.loggedUserSource.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    if(this.session == null) {
      this.getSession().subscribe(session => {
        this.session = session;
      });
    }
  }

  getSession() : Observable<Session> {
    //console.log('the app is attempting to get session info at the following api url: ',this.sessionUrl);
    return this.http.get<Session>(this.sessionUrl, httpOptions);
  }
  logUserIn(login: Login): Observable<Session> { // change to <Session>
    return this.http.post<Session>(this.sessionUrl, login, httpOptions); // change to <Session>
  }
  logUserOut(): Observable<any> {
    this.loggedIn = false;
    this.setLoggedState(false);
    localStorage.clear();
    return this.http.get<any>(this.logoutUrl, httpOptions);
  }
  isAuthenticated(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    /*we ask if isLoggedIn first - in case of page reload, this will set this.groups
     *before hasPermission is called
     *
     */
    if(this.isLoggedIn()) {
      if(this.hasPermission(route, state)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  isLoggedIn(): boolean {
    this.loggedIn = true;
    if(this.session == null) {

      var wasActive = localStorage.getItem('active');
      this.loggedIn = wasActive == 'true' ? true : false;
      this.getSession().subscribe(auth => {
        //console.log(auth);
        if(auth.user_session_id == null) {
          //console.log('this did not happen because auth=',auth);
          this.logUserOut().subscribe();
          
          return false;
        } else {
          //console.log('this happened');
          this.session = auth;
        }
      });
      if(!this.loggedIn) { this.logUserOut().subscribe(); }
      return this.loggedIn;
    } else {
      if(this.session.user_session_id !== 'set') {
        return false;
      }
      return true;
    }
  }
  hasPermission(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let roles = route.data.roles as Array<string>;
    if(roles == null) { 
      return true;
    } else {
      var userHasPermission:boolean = false;
      this.groups.forEach(group => {
        if(!userHasPermission && roles.includes(group.group_name)) {
          userHasPermission = true;
        }
      });
      return userHasPermission;
    }
  }
  isSession(obj: any): obj is Session {
    return 'user_session_id' in obj;
  }
  setLoggedState(state: boolean) {
    this.loggedStateSource.next(state);
  }
  setLoggedUser(user: string) {
    this.loggedUserSource.next(user);
  }
}
