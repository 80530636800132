import { Component, OnInit } from '@angular/core';
import { DetailService } from '../../services/detail.service';
import { Detail } from '../../models/Detail';

@Component({
  selector: 'app-irontype',
  templateUrl: './irontype.component.html',
  styleUrls: ['./irontype.component.css']
})
export class IronTypeComponent implements OnInit {

  // Properties
  irontypes: Detail[];
  displayed: Detail[];
  searchResults: Detail[];
  showCount: number = 24;
  requestCount: number = 24;
  currentPageIndex: number = 0;
  pageCount: number;
  data: any;
  loaded: boolean = false;
  private checkInQueue: boolean = false;
  formNew: boolean = true;
  currentIronType: Detail;
  searchbar: string;

  constructor(private irontypeService: DetailService) {}


  ngOnInit() { // use this to fill properties from ajax call to server etc.
    
    this.blankIronTypeForm();

    this.irontypes = JSON.parse(localStorage.getItem('irontypes'));
    if(this.irontypes) {
      this.setView();
      this.loaded = true;
    }

    this.irontypeService.getDetails("irontype").subscribe(irontypes => {
      this.irontypes = irontypes;
      localStorage.setItem('irontypes', JSON.stringify(this.irontypes));
      this.setView();
      this.loaded = true;
    });

  }
  prevPage() {
    if(this.currentPageIndex > 0) {
      this.currentPageIndex -= 1;
      this.setView();
    }
  }
  nextPage() {
    if(this.currentPageIndex + 1 < this.pageCount) {
      this.currentPageIndex += 1;
      this.setView();
    }
  }
  setView() {
    //console.log('execute setView()')
    if(this.searchResults) {
      //console.log('searchResults not empty so we complete all the logic for setView');
      //console.log('by the way, check out searchResults:');
      //console.log(this.searchResults);
      this.displayed = [];
      this.showCount = Math.min(this.requestCount, this.searchResults.length);
      this.pageCount = this.showCount == 0 ? 0 : Math.ceil(this.searchResults.length/this.showCount);
      this.currentPageIndex = Math.min(this.currentPageIndex, Math.max(this.pageCount - 1, 0));
      for (
        let i = this.currentPageIndex * this.showCount; 
        i < this.currentPageIndex * this.showCount + Math.min(this.searchResults.length - this.currentPageIndex * this.showCount, this.requestCount); 
        i++
      ) {
        this.displayed.push(this.searchResults[i]);
      }
      //console.log(this.displayed);
    } else {
      //console.log('searchResults empty so we must execute search()');
      this.search();
    }
  }

  search() {
    //console.log('execute search()');
    if(this.irontypes) {
      //console.log('irontypes not empty so we proceed...');
      if(this.searchbar) {
        //console.log('searchbar not empty so we go through irontypes and populate searchResults');
        
        this.searchResults = [];
        for (let i = 0; i < this.irontypes.length; i++) {
          if(this.irontypes[i].name.toString().toLowerCase().includes(this.searchbar.toLowerCase())) {
            this.searchResults.push(this.irontypes[i]);
          }
        }
      } else {
        //console.log('searchbar is empty so we set searchResults to irontypes');
        this.searchResults = this.irontypes;
      }
      //this.currentPageIndex = 0;
      //console.log('search() is now ready to call setView()');
      this.setView();
    }
  }

  onSubmit({value, valid}: {value: Detail, valid: boolean}) {
    //close the modal popup
    document.getElementById('closeaddEditIronTypeModal').click();
    //send create/update request
    if(valid) {
      if(this.formNew) {
        this.irontypes.unshift(this.currentIronType);
      } else {
        let i = 0;
        this.irontypes.forEach((irontype) => {
          if(irontype.id === this.currentIronType.id) {
            this.irontypes.splice(i,1,{
                id: this.currentIronType.id,
                company_id: this.currentIronType.company_id,
                name: this.currentIronType.name
            });
          }
          i++;
        });
      }
      //reset searchResults and prepare data for page viewing
      delete this.searchResults;
      this.setView();

      //request the create/update from the service
      this.irontypeService.addEditDetail("irontype", {
        id:this.currentIronType.id,
        company_id:this.currentIronType.company_id,
        name:this.currentIronType.name
      } as Detail).subscribe(irontypes => {
        this.irontypes = irontypes;
        delete this.searchResults;
        localStorage.setItem('irontypes', JSON.stringify(this.irontypes));
        this.setView();
      });
      //reset form
      if(this.formNew) { this.blankIronTypeForm(); }
    }
  }

  deleteIronType() {
    //close the modal popup
    document.getElementById('closeDeleteIronTypeModal').click();
    //remove the irontype from the list
    let i = 0;
    this.irontypes.forEach((irontype) => {
      if(irontype.id === this.currentIronType.id) {
        this.irontypes.splice(i,1);
      } else {
        i++;
      }
    });
    //reset searchResults and prepare data for page viewing
    delete this.searchResults;
    this.setView();

    //request a irontype deletion from the service
    this.irontypeService.deleteDetail("irontype", this.currentIronType.id as number).subscribe(irontypes => {
      this.irontypes = irontypes;
      localStorage.setItem('irontypes', JSON.stringify(this.irontypes));

      //reset searchResults and prepare data for page viewing
      delete this.searchResults;
      this.setView();
    });
    //reset currentIronType
    this.blankIronTypeForm();
  }

  refreshForm() {
    this.formNew=true;
    this.blankIronTypeForm();
  }

  blankIronTypeForm() {
    this.currentIronType = {
      id: null,
      company_id: 2,
      name: ""
    };
  }

}
