import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/Forms';
import { HttpClientModule } from '@angular/common/http';

import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { UsersComponent } from './components/users/users.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { CastingsComponent } from './components/castings/castings.component';
import { CastingFormComponent } from './components/casting-form/casting-form.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { IronTypeComponent } from './components/irontype/irontype.component';
import { MachineShopComponent } from './components/machineshop/machineshop.component';
import { PaintComponent } from './components/paint/paint.component';
import { JobDescComponent } from './components/jobdescription/jobdescription.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { TicketsComponent } from './components/tickets/tickets.component';
import { TicketFormComponent } from './components/ticket-form/ticket-form.component';
import { DocumentsComponent } from './components/documents/documents.component';

import { AuthService } from './services/auth.service';
import { AuthGuardService } from './services/authguard.service';
import { MessageService } from './services/message.service';
import { UserService } from './services/user.service';
import { CastingService } from './services/casting.service';
import { ContactService } from './services/contact.service';
import { DetailService } from './services/detail.service';
import { TicketService } from './services/ticket.service';
import { UploadFileService } from './services/upload-file.service';
import { AppRoutingModule } from './/app-routing.module';




@NgModule({
  declarations: [
    AppComponent,
    UsersComponent,
    NavbarComponent,
    CastingsComponent,
    CastingFormComponent,
    ContactsComponent,
    ContactFormComponent,
    IronTypeComponent,
    MachineShopComponent,
    PaintComponent,
    JobDescComponent,
    LoginComponent,
    HomeComponent,
    TicketsComponent,
    TicketFormComponent,
    DocumentsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule
  ],
  providers: [
    AuthService,
    AuthGuardService,
    MessageService,
    UserService,
    CastingService,
    ContactService,
    DetailService,
    TicketService,
    UploadFileService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
