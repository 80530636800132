import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';

import { Detail } from '../models/Detail';
import { environment } from '../../environments/environment';
const httpOptions = {
  withCredentials: true,
  headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'X-API-KEY': 'insert_api_key_here'
  })
}

@Injectable({
  providedIn: 'root'
})
export class DetailService {
  detailsUrl: string = environment.apiUrl+'?getDetails';
  addEditUrl: string = environment.apiUrl+'?addEditDetail';
  deleteUrl: string = environment.apiUrl+'?deleteDetail';

  ironTypes: Detail[];
  paints: Detail[];
  machVendors: Detail[];
  descriptions: Detail[];


  constructor(private http: HttpClient) { }
  setDetails(objName: string, details: Detail[]) {
    let sorted = details.sort((a,b) => {
      if(a.name.toLowerCase() < b.name.toLowerCase()) { 
        return -1;
      } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      } else {
        return 0;
      }
    });
    this[objName] = sorted;
  }
  getDetails(type: string): Observable<Detail[]> {
    return this.http.get<Detail[]>(this.detailsUrl+"="+type, httpOptions);
  }
  addEditDetail(type: string, detail: Detail): Observable<Detail[]> {
    return this.http.post<Detail[]>(this.addEditUrl+"="+type, detail, httpOptions);
  }
  deleteDetail(type: string, id: number): Observable<Detail[]> {
    return this.http.post<Detail[]>(this.deleteUrl+"="+type, id, httpOptions);
  }
}
