import { Injectable } from '@angular/core';
import { Message } from '../models/Message';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor() { }
  containsMessage(obj: any): obj is Message {
    return 'has_message' in obj;
  }
}
