import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';

import { User } from '../models/User';
import { environment } from '../../environments/environment';
// const httpOptions = {
//   withCredentials: true,
//   headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'})
// }
const httpOptions = {
  withCredentials: true,
  headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'X-API-KEY': 'insert_api_key_here'
  })
}


@Injectable({
  providedIn: 'root'
})
export class UserService {
  usersUrl: string = environment.apiUrl+'?users';
  //usersUrl: string = 'https://jsonplaceholder.typicode.com/users';
  addEditUrl: string = environment.apiUrl+'?addEditUser';
  findUserUrl: string = environment.apiUrl+'?findUser';
  deleteUrl: string = environment.apiUrl+'?deleteUser';

  users: Observable<User[]>;
  data: Observable<any>;

  constructor(private http: HttpClient) { }
  getUsers(): Observable<User[]> {
    //console.log('fetching users from service');
    //return of(this.users);
    this.users = this.http.get<User[]>(this.usersUrl, httpOptions);
    return this.users;
  }
  addEditUser(user: User): Observable<User[]> {
    return this.http.post<User[]>(this.addEditUrl, user, httpOptions);
  }
  deleteUser(id: number): Observable<User[]> {
    return this.http.post<User[]>(this.deleteUrl, id, httpOptions);
  }
  usernameExists(handle: string, username: boolean): Observable<boolean> {
    const url = username ? this.findUserUrl : this.findUserUrl + '=email';
    return this.http.post<boolean>(url, handle, httpOptions);
  }
  getData() {
    this.data = new Observable(observer => {
      setTimeout(() => {
        observer.next(1);
      }, 1000);
      setTimeout(() => {
        observer.next(2);
      }, 2000);
      setTimeout(() => {
        observer.next(3);
      }, 3000);
    });
    return this.data;
  }
}
