// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: true,
  apiUrl: "https://dev-api.trackercast.com/api/",
  appUrl: "https://dev.trackercast.com/"
};
// appUrl: "https://dev.trackercast.com/"


// The following is the normal local environment...
// export const environment = {
//   production: false,
//   apiUrl: "http://localhost:8080/trackercast/api/",
//   appUrl: "http://localhost:4200/"
// };

/* http://trackercastapi-env.naruffpjpp.us-east-2.elasticbeanstalk.com/api/
 * http://localhost:81/jobtracker/api/
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
