import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';

import { Contact } from '../models/Contact';
import { environment } from '../../environments/environment';
// const httpOptions = {
//   withCredentials: true,
//   headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'})
// }
const httpOptions = {
  withCredentials: true,
  headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'X-API-KEY': 'insert_api_key_here'
  })
}


@Injectable({
  providedIn: 'root'
})
export class ContactService {
  contactsUrl: string = environment.apiUrl+'?contacts';
  companiesUrl: string = environment.apiUrl+'?getCustomers';
  addEditUrl: string = environment.apiUrl+'?addEditContact';
  findContactUrl: string = environment.apiUrl+'?findContact';
  deleteUrl: string = environment.apiUrl+'?deleteContact';
  contacts: Contact[];

  data: Observable<any>;

  constructor(private http: HttpClient) { }
  setContacts(contacts: Contact[]) {
    this.contacts = contacts.sort((a,b) => {
      if(a.contact < b.contact) { 
        return -1;
      } else if (a.contact > b.contact) {
        return 1;
      } else {
        return 0;
      }
    });
  }
  getContacts(): Observable<Contact[]> {
    //console.log('fetching contacts from service');
    //return of(this.contacts);
    return  this.http.get<Contact[]>(this.contactsUrl, httpOptions);
  }
  getCustomers(): Observable<string[]> {
    return this.http.get<string[]>(this.companiesUrl, httpOptions);
  }
  addEditContact(contact: Contact): Observable<Contact[]> {
    return this.http.post<Contact[]>(this.addEditUrl, contact, httpOptions);
  }
  deleteContact(id: number): Observable<Contact[]> {
    return this.http.post<Contact[]>(this.deleteUrl, id, httpOptions);
  }
}