import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';

import { Ticket } from '../models/Ticket';
import { environment } from '../../environments/environment';
// const httpOptions = {
//   withCredentials: true,
//   headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'})
// }
const httpOptions = {
  withCredentials: true,
  headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'X-API-KEY': 'insert_api_key_here'
  })
}


@Injectable({
  providedIn: 'root'
})
export class TicketService {
  ticketsUrl: string = environment.apiUrl+'?tickets';
  addUrl: string = environment.apiUrl+'?addTicket';
  findTicketUrl: string = environment.apiUrl+'?findTicket';
  deleteUrl: string = environment.apiUrl+'?deleteTicket';
  tickets: Ticket[];

  data: Observable<any>;

  constructor(private http: HttpClient) { }
  setTickets(tickets: Ticket[]) {
    this.tickets = tickets;
    //may want to custom sort in the future...
    // .sort((a,b) => {
    //   if(a.ticket < b.ticket) { 
    //     return -1;
    //   } else if (a.ticket > b.ticket) {
    //     return 1;
    //   } else {
    //     return 0;
    //   }
    // });
  }
  getTickets(): Observable<Ticket[]> {
    //console.log('fetching tickets from service');
    //return of(this.tickets);
    return  this.http.get<Ticket[]>(this.ticketsUrl, httpOptions);
  }
  addTicket(ticket: Ticket): Observable<Ticket> {
    return this.http.post<Ticket>(this.addUrl, ticket, httpOptions);
  }
  deleteTicket(id: number): Observable<Ticket[]> {
    return this.http.post<Ticket[]>(this.deleteUrl, id, httpOptions);
  }
  deleteTicketAndRemoveFromJob(ticket: Ticket): Observable<Ticket[]> {
    return this.http.post<Ticket[]>(this.deleteUrl + '&removeFromJob', ticket, httpOptions);
  }
  serveTicket(ticket: Ticket, swapOutNull: boolean = false) {
    if(this.tickets != null) {
      if(swapOutNull) {
        let i=0;
        this.tickets.forEach(row => {
          if(row.id === null) {
            this.tickets.splice(i,1,ticket);
          }
          i++;
        });
      } else {
        this.tickets.unshift(ticket);
      }
    }
  }
  ticketExists(ticket: string, company_id: number): Observable<boolean> {
    const url = this.findTicketUrl + "&no="+ticket+"&co="+company_id;
    return this.http.get<boolean>(url, httpOptions);
  }
}
