import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { MessageService } from '../../services/message.service';
import { User } from '../../models/User';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  // Properties
  users: User[];
  data: any;
  loaded: boolean = false;
  mismatch: boolean;
  usernameUnique: boolean;
  uniqueCheckComplete: boolean;
  emailUnique: boolean;
  emailCheckComplete: boolean;
  private checkInQueue: boolean = false;
  formNew: boolean = true;
  showDetails: boolean[];
  original = {
    username: "",
    email: ""
  }
  editUser: User;

  constructor(
    private userService: UserService,
    private messageService: MessageService
  ) {}

  ngOnInit() { // use this to fill properties from ajax call to server etc.
    //console.log('ngOnInit was called');
    this.blankUserForm();

    this.users = JSON.parse(localStorage.getItem('users'));
    if(this.users) {
      this.loaded = true;
    }

    this.userService.getUsers().subscribe(users => {
      if(this.users) {
        users.forEach((user) => {
          let shiftedUser = this.users.shift();
          user.showdetails = shiftedUser.id === user.id ? shiftedUser.showdetails : false;
          this.users.push(user);
        });
      } else {
        this.users = users;
      }
      
      // this.users = users;
      // this.users.forEach((user) => {
      //   user.showdetails = false;
      // });
      localStorage.setItem('users', JSON.stringify(this.users));
      this.loaded = true;
    });

  }

  onSubmit({value, valid}: {value: User, valid: boolean}) {
    //close the modal popup
    document.getElementById('closeaddEditUserModal').click();
    //send create/update request
    if(
      valid && 
      !this.mismatch && 
      this.usernameUnique && this.uniqueCheckComplete && 
      this.emailUnique && this.emailCheckComplete && 
      (
        this.editUser.passwordconfirm !== '' || 
        (!this.formNew && this.editUser.password === '')
      )
    ) {
      if(this.formNew) {
        //console.log('Adding user:', JSON.stringify(this.editUser));
        this.users.unshift(this.editUser);
      } else {
        let i = 0;
        this.users.forEach(user => {
          if(user.id == this.editUser.id) {
            //console.log('Replace user found with:', JSON.stringify(this.editUser));
            this.users.splice(i,1,this.editUser);
            //console.log('Now the users array is:', JSON.stringify(this.users));
          }
          i++;
        });
      }
      this.userService.addEditUser({
        id:this.editUser.id,
        company_id:this.editUser.company_id,
        username:this.editUser.username, 
        password:this.editUser.password,
        firstname:this.editUser.firstname,
        lastname:this.editUser.lastname,
        email:this.editUser.email
      } as User).subscribe(users => {
        this.users = users;
        localStorage.setItem('users', JSON.stringify(this.users));
      });
      //reset form
      //if(this.formNew) { this.blankUserForm(); }
      this.blankUserForm();
      // console.log('After onSubmit (before finished at service level):');
      // console.log('editUser:',JSON.stringify(this.editUser));
      // console.log('users:', JSON.stringify(this.users));
    }
  }

  deleteUser() {
    //close the modal popup
    document.getElementById('closeDeleteUserModal').click();
    //remove the user from the list
    let i = 0;
    this.users.forEach((user) => {
      if(user.id === this.editUser.id) {
        this.users.splice(i,1);
      } else {
        i++;
      }
    });
    //request a user deletion from the service
    this.userService.deleteUser(this.editUser.id as number).subscribe(users => {
      users.forEach((user) => {
        let shiftedUser = this.users.shift();
        user.showdetails = shiftedUser.id === user.id ? shiftedUser.showdetails : false;
        this.users.push(user);
      });
      localStorage.setItem('users', JSON.stringify(this.users));
    });
    //reset editUser
    this.blankUserForm();
  }

  mismatchUpdate() {
    this.mismatch = this.editUser.password !== this.editUser.passwordconfirm ? true : false;
  }

  checkUnique(handle?: string) {
    const lookfor = handle && handle === 'email' ? 'email' : 'username';
    if(
      (lookfor === 'username' && 
        (this.uniqueCheckComplete || this.uniqueCheckComplete === undefined)
      ) || (lookfor === 'email' &&
        (this.emailCheckComplete || this.emailCheckComplete === undefined)
      )
    ) {
      if(lookfor === 'username') {
        this.uniqueCheckComplete = false;
      } else {
        this.emailCheckComplete = false;
      }
      
      if(
        this.formNew || (
          this.original && (
            (this.editUser.username !== this.original.username && lookfor === 'username') || 
            (this.editUser.email !== this.original.email && lookfor === 'email')
          )
        )
      ) {
        this.userService.usernameExists(
          this.editUser[lookfor] as string, 
          (lookfor === 'username') as boolean
        ).subscribe(dne => {
          this.checkUniqueBookkeeper(lookfor, dne);
        });
      } else {
        this.checkUniqueBookkeeper(lookfor, true);
      }
    } else {
      this.checkInQueue = true;
    }
  }
  checkString(str: string, quest: string) {
    if(str != null) {
      var ch: any;
      for(let i=0; i < str.length; i++) {
        ch = str.charAt(i);
        if(quest == 'upper' && "ABCDEFGHIJKLMNOPQRSTUVWXYZ".includes(ch)) {
          return true;
        }
        if(quest == 'lower' && "abcdefghijklmnopqrstuvwxyz".includes(ch)) {
          return true;
        }
        if(quest == 'number' && "1234567890".includes(ch)) {
          return true;
        }
        if(quest == 'special' && "#?!@$%^&*-".includes(ch)) {
          return true;
        }
      }
    }
    return false;
  }
  checkUniqueBookkeeper(handle: string, unique: boolean) {
    if(handle === 'username') {
      this.usernameUnique = unique;
      this.uniqueCheckComplete = true;
    } else {
      this.emailUnique = unique;
      this.emailCheckComplete = true;
    }
    if(this.checkInQueue) {
      this.checkInQueue = false;
      this.checkUnique(handle);
    }
  }

  refreshForm() {
    this.formNew=true;
    this.blankUserForm();
  }

  blankUserForm() {
    this.editUser = {
      id: null,
      firstname: "",
      lastname: "",
      company_id: 2,
      username: "",
      email: "",
      password: "",
      passwordconfirm: ""
    };
  }

  prepareEdit(userObject: User) {
    this.editUser = userObject;
    this.formNew = false;
    this.original.email = userObject.email;
    this.original.username = userObject.username;
    this.usernameUnique = true;
    this.uniqueCheckComplete = true;
    this.emailUnique = true;
    this.emailCheckComplete = true;
  }

  toggleUser(user: User) {
    user.showdetails = !user.showdetails;
    localStorage.setItem('users', JSON.stringify(this.users));
  }
}
