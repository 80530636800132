import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ContactService } from '../../services/contact.service';
import { Contact } from '../../models/Contact';
@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {

  //properties
  @Input() currentContact: Contact;
  @Input() formNew: boolean;
  @Input() showLoading: boolean;
  @Input() showSuccessMessage: boolean;
  @Output() addEditContact: EventEmitter<Contact> = new EventEmitter();
  @Output() contactDeletion: EventEmitter<Contact> = new EventEmitter();

  companies: any[];
  filteredCompanies: string[];
  filterInProgress: boolean = false;
  filterInQueue: boolean = false;

  constructor(private contactService: ContactService) {}

  ngOnInit() {
    this.companies = JSON.parse(localStorage.getItem('allCompanies'));
    this.filteredCompanies = this.companies;
    this.contactService.getCustomers().subscribe(companies => {
      //console.log(companies);
      localStorage.setItem('allCompanies', JSON.stringify(companies));
      this.companies = companies;
      this.filteredCompanies = companies;
    });
  }

  onSubmit({value, valid}: {value: Contact, valid: boolean}) {
    //send create/update request
    if(valid) { 
      this.addEditContact.emit(this.currentContact);
    }
  }

  deleteContact() {
    this.contactDeletion.emit(this.currentContact);
  }

  filterCompanies() {
    if(!this.filterInProgress) {
      this.filterInProgress = true;
      this.filteredCompanies = [];
      this.companies.forEach((company) => {
        if(company.company.toString().toLowerCase().includes(this.currentContact.company.toLowerCase())) {
          this.filteredCompanies.push(company);
        }
      });
      this.filterInProgress = false;
    } else {
      if(this.filterInQueue) { this.filterCompanies(); }
      this.filterInQueue = !this.filterInQueue;
    }
  }
}
