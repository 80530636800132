import { Component, ViewChild, ElementRef, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ContactService } from '../../services/contact.service';
import { DetailService } from '../../services/detail.service';
import { Casting } from '../../models/Casting';
import { CastingService } from '../../services/casting.service';
import { UploadFileService } from '../../services/upload-file.service';
import { environment } from '../../../environments/environment';
declare var $:any;

@Component({
  selector: 'app-casting-form',
  templateUrl: './casting-form.component.html',
  styleUrls: ['./casting-form.component.css']
})
export class CastingFormComponent implements OnInit {
  //properties
  @Input() currentJob: Casting;
  @Input() formNew: boolean;
  @Input() formSwitchable: boolean;
  @Input() showLoading: boolean;
  @Input() showSuccessMessage: boolean;
  @Output() addEditJob: EventEmitter<Casting> = new EventEmitter();
  @Output() jobDeletion: EventEmitter<Casting> = new EventEmitter();
  @Output() changeFormNew: EventEmitter<boolean> = new EventEmitter();
  @Output() toggleDupFileMessage: EventEmitter<boolean> = new EventEmitter();
  @Output() toggleSaveReminder: EventEmitter<boolean> = new EventEmitter();
  @Output() toggleNewFileName: EventEmitter<boolean> = new EventEmitter();

  contacts: any[];
  ironTypes: any[];
  paints: any[];
  descriptions: any[];
  machVendors: any[];
  filteredContacts: string[];
  filteredDescriptions: string[];
  filterInProgress: boolean = false;
  filterInQueue: boolean = false;
  private docCheckInQueue: boolean = false;
  private checkInProgress: boolean = false;
  autogenJobNo: boolean = false;
  jobBackup: {id: number, jobno: string};
  private jobnoUnique: boolean;
  private uniqueCheckComplete: boolean;
  private checkInQueue: boolean = false;
  invalidChars: boolean = false;
  private link: any = environment.appUrl + 'tickets';

  @ViewChild('fileSelectionInput') fileSelectionInput?: ElementRef;
  //properties for attached docs
  @Input() documents: any[];
  @Input() currentDocument: any;
  @Input() useNewFileName: boolean;
  @Input() saveReminder: boolean;
  @Input() showDuplicateFileMessage: boolean = false;
  @Output() blankDocument: EventEmitter<boolean> = new EventEmitter();
  @Output() fileSelected: EventEmitter<FileList> = new EventEmitter();
  @Output() addEditDoc: EventEmitter<any> = new EventEmitter();
  selectedFiles: FileList;
  filenameUnique: boolean;
  formReady: boolean=true;

  constructor(
    private contactService: ContactService,
    private detailService: DetailService,
    private jobService: CastingService,
    private uploadService: UploadFileService
  ) { }
  ngOnInit() {
    var detailTypes = [['irontype','ironTypes'],['paint','paints'],['jobdescription','descriptions'],['machineshop','machVendors']];
    this.contacts = this.contactService.contacts;
    detailTypes.forEach(handles => {
      this[handles[1]] = this.detailService[handles[1]];
    });
    
    this.contactService.getContacts().subscribe(contacts => {
      this.contactService.setContacts(contacts);
      this.contacts = this.contactService.contacts;
    });

    detailTypes.forEach(handles => {
      this.detailService.getDetails(handles[0]).subscribe(details => {
        this.detailService.setDetails(handles[1],details);
        this[handles[1]] = this.detailService[handles[1]];
      });
    });
  }
  openNewTicketsWindow() {
    window.open(
      this.link, 
      'newwindow', 
      'width=900,height=500'
    ); 
    return false;
  }
  toggleForm() {
    if(this.formSwitchable) {
      var toggle = document.getElementById('formSwitchToggle') as HTMLInputElement;
      this.formNew = !toggle.checked;
      this.changeFormNew.emit(this.formNew);
      //if(this.formNew) { console.log('bc - form is new'); } else { console.log('bc - form is not new'); }
      if(this.formNew) {
        if(this.jobBackup) {
          this.jobBackup.jobno = this.currentJob.jobno;
          this.jobBackup.id = this.currentJob.id;
        } else {
          this.jobBackup = {
            id: this.currentJob.id,
            jobno: this.currentJob.jobno
          }
        }
        this.currentJob.id = null;
        this.currentJob.jobno = "";
      } else if(this.currentJob.id == null && this.jobBackup) {
        this.currentJob.id = this.jobBackup.id;
        this.currentJob.jobno = this.jobBackup.jobno;
      }
    }
  }
  showChange() { //attach to an event for debugging purposes
    //var el = document.querySelector(".toggle-group");
    //if(el != null) {
    //  console.log(el);
    //}
    //console.log(this.currentJob);
    //this.formNew = !this.formNew;
    //console.log(this.formNew);
  }
  onSubmit({value, valid}: {value: Casting, valid: boolean}) {
    //send create/update request
    if(valid) {
      if(!this.formNew || 
        ((this.jobnoUnique && this.uniqueCheckComplete) || this.autogenJobNo)
      ) {
        if(this.selectedFiles != null) {
          this.fileSelected.emit(this.selectedFiles);
          this.addEditDoc.emit(this.currentDocument);
        }
        if(this.formNew && this.autogenJobNo) {
          this.currentJob.jobno = "";
        }
        console.log('Sending job from form to main component...');
        this.addEditJob.emit(this.currentJob);
      }
    }
    // console.log('valid?', valid);
    // console.log('form new?', this.formNew);
    // console.log('unique jobno?', this.jobnoUnique);
    // console.log('unique check complete?', this.uniqueCheckComplete);
    // console.log('auto generate jobno?', this.autogenJobNo);
  }
  selectFile(event) {
    this.selectedFiles = event.target.files;
    this.currentDocument.filename = this.selectedFiles.item(0).name;
    
    //console.log('the file has just been selected:',this.currentDocument);
    this.toggleSaveReminder.emit(true);
    //check uniqueness if the form is NOT new
    if(!this.formNew) {
      this.checkFileUniqueness();
      //toggle visibility of non-unique-warning message and new name input
      this.toggleDupFileMessage.emit(!this.filenameUnique);
      this.toggleNewFileName.emit(!this.filenameUnique);
    }
    
    //console.log('the file metadata has been updated:',this.currentDocument);
  }
  checkFileUniqueness() {
    //console.log("currentDocument jobnumber when checkFileUni.. is called:", this.currentDocument.jobnumber);
    this.checkInProgress = true;

    //now we need to make sure we have the correct folder prefix
    this.currentDocument.folder_prefix = "americanpattern/";
    this.currentDocument.jobnumber = this.currentJob.jobno;
    this.currentDocument.folder_prefix += this.currentDocument.jobnumber + "/";

    //console.log('currentDocument has correct folder prefix set for the job selected:',this.currentDocument.folder_prefix);
    var uniqueName = true;
    if(this.documents != null) {
      this.documents.forEach(doc => {
        if(
          doc.filename == this.currentDocument.filename && 
          (doc.folder_prefix == this.currentDocument.folder_prefix)
        ) { 
          uniqueName = false;
          //For reference in this comment, let folder_prefix = 'americanpattern/{jobno}/':
          //Here, we update the id and comments to reflect the conflicting document.
          //We do NOT update the jobnumber, because, (although the jobnumber should 
          //be the same as jobno) if for some reason jobnumber!=jobno, we still
          //allow the user to change the job number, thus correcting this difference in the case
          //of an older file overwrite. In the case of a new file with a name change, we still don't
          //want the job number to change after the user selects it (e.g. attempt to select TC1 and 
          //input switches to TC2 when checkFileUniqueness() is called).
          //This applies farther below as well, when checking for uniqueness against the database.
          this.currentDocument.id = doc.id;
          this.currentDocument.comments = doc.comments;
        }
      });
    }

    this.filenameUnique = uniqueName;
    if(uniqueName) {
      //in case the user has selected a document, then selected another before uploading:
      //we want to make sure the document id is set to null
      this.currentDocument.id = null;
      //console.log('document sent to api for check:',this.currentDocument);
      this.uploadService.singleFileMetadata(this.currentDocument).subscribe(theDoc => {
        if(theDoc != null && theDoc.length > 0) {
          this.currentDocument.id = theDoc[0].id;
          this.currentDocument.comments = theDoc[0].comments;
          this.filenameUnique = false;
        } else {
          this.filenameUnique = true;
        }


        if(this.docCheckInQueue) {
          this.docCheckInQueue = false;
          this.checkFileUniqueness();
        } else {
          this.checkInProgress = false;
        }
        //console.log('filename unique (inside subscription):',this.filenameUnique);
      });
    } else {
      if(this.docCheckInQueue) {
        this.docCheckInQueue = false;
        this.checkFileUniqueness();
      } else {
        this.checkInProgress = false;
      }
    }

    
    //console.log('filename unique (outside subscription):',this.filenameUnique);
  }
  checkNewNameUnique() {
    //console.log('check in progress:', this.checkInProgress);

    if(this.currentDocument.filename != "" && this.currentDocument.filename != null) {
      if(!this.checkInProgress) {
        this.checkFileUniqueness();
        this.toggleDupFileMessage.emit(!this.filenameUnique);
      } else {
        this.checkInQueue = true;
      }
    } else {
      this.toggleDupFileMessage.emit(true);
    }
  }
  signedUrl(filename: string, jobnumber: string): string {
    return this.uploadService.getFileUrl(filename,jobnumber);
  }
  openInNewTab(filename: string, jobnumber: string) {
    window.open(this.signedUrl(filename,jobnumber), '_blank');
  }
  openFile(filename: string, jobnumber: string) {
    window.open(this.signedUrl(filename,jobnumber));
  }
  deleteJob() {
    this.jobDeletion.emit(this.currentJob);
  }
  testfunc() {
    //console.log('show dup message:',this.showDuplicateFileMessage);
    //console.log('show save reminder:',this.saveReminder);
    //console.log('use new filename:',this.useNewFileName);
  }
  addMachShipment() {
    this.currentJob.machship.push({"count": 0, "date": ""});
  }

  addFinalShipment() {
    this.currentJob.finalship.push({"count": 0, "date":""});
  }
  trackByIndex(index: number, obj: any): any {
    return index;
  }
  filterContacts() {
    if(!this.filterInProgress) {
      this.filterInProgress = true;
      this.filteredContacts = [];
      if(this.contacts != null) {
        this.contacts.forEach((contact) => {
          if(
            contact.contact.toString().toLowerCase().includes(this.currentJob.contact.toLowerCase()) ||
            contact.company.toString().toLowerCase().includes(this.currentJob.contact.toLowerCase()) ||
            contact.phone.toString().toLowerCase().includes(this.currentJob.contact.toLowerCase())
          ) {
            this.filteredContacts.push(contact);
          }
        });        
      }
      this.filterInProgress = false;
    } else {
      if(this.filterInQueue) { this.filterContacts(); }
      this.filterInQueue = !this.filterInQueue;
    }
  }
  filterDescriptions() {
    if(!this.filterInProgress) {
      this.filterInProgress = true;
      this.filteredDescriptions = [];
      if(this.descriptions != null) {
        this.descriptions.forEach((description) => {
          if(description.name.toString().toLowerCase().includes(this.currentJob.description.toLowerCase())) {
            this.filteredDescriptions.push(description);
          }
        });
      }
      this.filterInProgress = false;
    } else {
      if(this.filterInQueue) { this.filterDescriptions(); }
      this.filterInQueue = !this.filterInQueue;
    }
  }
  totalShipped(shipments) {
    let total = 0;
    if(shipments != null) {
      shipments.forEach(shipment => { total += (parseInt(shipment.count,10) || 0); });
    }
    
    return total;
  }
  checkValid() {
    if(this.currentJob.jobno.substring(0,2).toLowerCase() == 'tc') {
      this.invalidChars = true;
    } else {
      this.invalidChars = false;
      this.checkUnique();
    }
  }
  checkUnique() {
    if(this.uniqueCheckComplete || this.uniqueCheckComplete === undefined) {
      this.uniqueCheckComplete = false;
      
      if(this.formNew) {
        this.jobService.jobnoExists(
          this.currentJob.jobno as string,
          this.currentJob.company_id as number
        ).subscribe(dne => {
          //console.log(dne);
          this.checkUniqueBookkeeper(dne);
        });
      } else {
        this.checkUniqueBookkeeper(true);
      }
    } else {
      this.checkInQueue = true;
    }
    //console.log('error should be hidden because: HIDE? -',this.jobnoUnique || !this.uniqueCheckComplete)
  }
  checkUniqueBookkeeper(unique: boolean) {
    this.jobnoUnique = unique;
    this.uniqueCheckComplete = true;
    if(this.checkInQueue) {
      this.checkInQueue = false;
      this.checkUnique();
    }
  }
}
